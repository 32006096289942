<script>
import PortalComponentMixin from "../../mixins/PortalComponentMixin";

export default {
  mixins: [PortalComponentMixin],
  data() {
    return {
      loading: true
    }
  },
  methods: {
    load() {
      // load() is to be overridden and return a Promise when it is done
    },
    reload() {
      this.loading = true;

      this.load().then(result => {
        this.loading = false;

        return result;
      }).catch(error => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
