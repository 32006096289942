import moment from 'moment';

export const state = () => ({
    isSiteProfileLoading: false,
    isCommunicationMediumsLoading: false,
    isConsentTemplatesLoading: false,
    isConsentDeliveryMethodsLoading: false,
    isConsentUpdating: false,
    isPatientJourneyUpdating: false,
    error: null,
    consentsUpdateError: null,
    communicationMediums: [],
    consentDeliveryMethods: [],
    consentTemplates: [],
    siteProfile: null,
    lastUpdatedDate: null,
    profilesPreferences: JSON.parse(window.localStorage.getItem('profilesPreferences')) || {},
    profileCareTeamComposable: null,
    focusItem: {
        sectionId: null,
        item: null,
    },
});

export const mutations = {
    SET_PROFILE_PREFERENCES (state, { profileId, preferences }) {
        state.profilesPreferences[profileId] = preferences;
    },
    SET_CURRENT_PROFILE (state, profile) {
        state.siteProfile = profile;
        state.lastUpdatedDate = moment();
    },
    SET_COMMUNICATION_MEDIUMS (state, mediums) {
        state.communicationMediums = mediums;
    },
    SET_SITE_PROFILE_LOADING (state, loading) {
        state.isSiteProfileLoading = loading;
    },
    SET_COMMUNICATION_MEDIUMS_LOADING (state, loading) {
        state.isCommunicationMediumsLoading = loading;
    },
    SET_CONSENT_TEMPLATES (state, templates) {
        state.consentTemplates = templates;
    },
    SET_CONSENT_TEMPLATES_LOADING (state, loading) {
        state.isConsentTemplatesLoading = loading;
    },
    SET_CONSENT_DELIVERY_METHODS (state, methods) {
        state.consentDeliveryMethods = methods;
    },
    SET_CONSENT_DELIVERY_METHODS_LOADING (state, loading) {
        state.isConsentDeliveryMethodsLoading = loading;
    },
    SET_CONSENT_UPDATING (state, loading) {
        state.isConsentUpdating = loading;
    },
    SET_ERROR (state, error) {
        state.error = error;
    },
    UPDATE_CONSENT (state, updatedConsent) {
        if (state.siteProfile && state.siteProfile.profile && state.siteProfile.profile.consents) {
            state.siteProfile.profile.consents = updatedConsent;
        }
    },
    SET_CONSENTS_UPDATE_ERROR (state, error) {
        state.consentsUpdateError = error;
    },
    UNLOAD_CURRENT_PROFILE (state) {
        state.siteProfile = null;
    },
    SET_FOCUS (state, focusData) {
        state.focusItem = focusData;
    },
    SET_IS_PATIENT_JOURNEYS_UPDATING (state, updating) {
        state.isPatientJourneyUpdating = updating;
    },
    ADD_PATIENT_JOURNEY (state, patientJourney) {
        if (state.siteProfile && state.siteProfile.profile && state.siteProfile.profile.patient_journeys) {
            const existingJourneyIndex = state.siteProfile.profile.patient_journeys.findIndex(patient_journey => {
                return patient_journey.status === patientJourney.status;
            });
            if (existingJourneyIndex !== -1) {
                state.siteProfile.profile.patient_journeys.splice(existingJourneyIndex, 1, patientJourney);
            } else {
                state.siteProfile.profile.patient_journeys.push(patientJourney);
            }
        }
    },
    DELETE_PATIENT_JOURNEY (state, patientJourneyId) {
        if (state.siteProfile && state.siteProfile.profile && state.siteProfile.profile.patient_journeys) {
            state.siteProfile.profile.patient_journeys = state.siteProfile.profile.patient_journeys.filter(journey => journey.id !== patientJourneyId);
        }
    },
    SET_CARE_TEAM_COMPOSABLE (state, composable) {
        state.profileCareTeamComposable = composable;
    }
};

export const actions = {
    setProfileCareTeamComposable ({ commit }, composable) {
        commit('SET_CARE_TEAM_COMPOSABLE', composable);
    },
    updateProfilePreference ({ commit, state }, { profileId, key, value }) {
        const profilePreferences = state.profilesPreferences[profileId] || {};
        const updatedProfilePreferences = { ...profilePreferences, [key]: value };

        const updatedProfilesPreferences = {
            ...state.profilesPreferences,
            [profileId]: updatedProfilePreferences
        };

        window.localStorage.setItem('profilesPreferences', JSON.stringify(updatedProfilesPreferences));
        commit('SET_PROFILE_PREFERENCES', { profileId, preferences: updatedProfilePreferences });
    },
    unloadSiteProfile ({ commit }) {
        commit('UNLOAD_CURRENT_PROFILE');
    },
    async loadSiteProfile ({ commit }, siteProfileId) {
        commit('SET_SITE_PROFILE_LOADING', true);
        try {
            const response = await this.$apiv2.getSiteProfileDetails(siteProfileId);
            commit('SET_CURRENT_PROFILE', response);
            return response;
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_SITE_PROFILE_LOADING', false);
        }
    },
    async reloadSiteProfile ({dispatch, getters}) {
        if (getters.siteProfileId) {
            const response = await dispatch('loadSiteProfile', getters.siteProfileId);
            return response;
        } else {
            throw new Error('No site profile loaded');
        }
    },
    async getCommunicationMediums ({ commit }) {
        commit('SET_COMMUNICATION_MEDIUMS_LOADING', true);
        try {
            const response = await this.$apiv2.getCommunicationMediums();
            commit('SET_COMMUNICATION_MEDIUMS', response);
            return response;
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_COMMUNICATION_MEDIUMS_LOADING', false);
        }
    },
    async getConsentTemplates ({commit}) {
        commit('SET_CONSENT_TEMPLATES_LOADING', true);
        try {
            const response = await this.$apiv2.getConsentTemplates();
            commit('SET_CONSENT_TEMPLATES', response);
            return response;
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_CONSENT_TEMPLATES_LOADING', false);
        }
    },
    async getConsentDeliveryMethods ({commit}) {
        commit('SET_CONSENT_DELIVERY_METHODS_LOADING', true);
        try {
            const response = await this.$apiv2.getConsentDeliveryMethods();
            commit('SET_CONSENT_DELIVERY_METHODS', response);
            return response;
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_CONSENT_DELIVERY_METHODS_LOADING', false);
        }
    },
    async updateConsent ({ commit, getters }, { consent }) {
        commit('SET_CONSENTS_UPDATE_ERROR', null);
        commit('SET_CONSENT_UPDATING', true);
        try {
            const updatedConsent = await this.$apiv2.updateProfileConsent(getters.profileId, consent);
            commit('UPDATE_CONSENT', updatedConsent);
            return updatedConsent;
        } catch (error) {
            commit('SET_CONSENTS_UPDATE_ERROR', error);
        } finally {
            commit('SET_CONSENT_UPDATING', false);
        }
    },
    async createConsentDocument ({commit, getters, dispatch}, {templateId, role, medium='EMAIL', language='English'}) {
        commit('SET_CONSENTS_UPDATE_ERROR', null);
        commit('SET_CONSENT_UPDATING', true);
        try {
            await this.$apiv2.createConsentDocument({
                document: {
                    templateId: {
                        id: templateId,
                        signers: [
                            {
                                order: 1,
                                roleName: role,
                                email: getters.profile.owner?.email,
                                phoneNumber: getters.primaryContact,
                                action: "sign",
                            }
                        ],
                    },
                    name: "SignConsent",
                },
                medium,
                language,
            })
        } catch (error) {
            commit('SET_CONSENTS_UPDATE_ERROR', error);
        } finally {
            dispatch('loadSiteProfile', getters.siteProfileId)
        }
        commit('SET_CONSENT_UPDATING', false);
    },
    async getConsentDocumentLink ({commit}, documentId) {
        try {
            return await this.$apiv2.getConsentDocument(documentId);
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_CONSENT_TEMPLATES_LOADING', false);
        }
    },
    async getConsentDocumentViewLink ({commit}, documentId) {
        try {
            return await this.$apiv2.getConsentDocumentView(documentId);
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_CONSENT_TEMPLATES_LOADING', false);
        }
    },
    setFocus ({ commit }, focusData) {
        commit('SET_FOCUS', focusData);
        setTimeout(() => {
            commit('SET_FOCUS', {
                sectionId: null,
                item: null,
            });
        }, 3000);
    },
    async addPatientJourney ({ commit, getters }, patientJourney) {
        try {
            const newPatientJourney = await this.$apiv2.addPatientJourney(getters.profileId, patientJourney);
            commit('ADD_PATIENT_JOURNEY', newPatientJourney);
            return updatedPatientJourneys;
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_IS_PATIENT_JOURNEYS_UPDATING', false);
        }
    },
    async deletePatientJourney ({ commit, getters }, patientJourneyId) {
        try {
            await this.$apiv2.deletePatientJourney(patientJourneyId, getters.profileId);
            commit('DELETE_PATIENT_JOURNEY', patientJourneyId);
            return updatedPatientJourneys;
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_IS_PATIENT_JOURNEYS_UPDATING', false);
        }
    },
    async assignPractitioner({getters}, {npi, type}) {
        return await this.$apiv2.assignPractitionerToProfile({
            profileId: getters.profileId,
            npi,
            type,
        })
    },
    async unassignPractitioners({commit, getters}, {practitioners}) {
        return await this.$apiv2.unassignPractitionersFromProfile({
            profileId: getters.profileId,
            practitioners,
        })
    }
};

export const getters = {
    communicationMediumsConsents: state => state.communicationMediums.map(medium => ({ medium: medium, consent: null, source: "" })),
    lastUpdatedDateFromNow: (state) => state.lastUpdatedDate ? state.lastUpdatedDate.fromNow() : null,
    siteProfileLoaded: state => state.siteProfile !== null,
    siteProfileId: state => state.siteProfile && state.siteProfile.id,
    profile: state => state.siteProfile && state.siteProfile.profile,
    profileId: (_, getters) => getters.profile && getters.profile.id,
    profileConsents: (_, getters) => getters.profile && getters.profile.consents,
    profilePractitioners: (_, getters) => getters.profile && getters.profile.profile_practitioners || [],
    primaryContact: (_, getters) => {
        if (getters.profile && getters.profile.phone_number_as_e164 && (getters.profile.phone_number_as_e164 !== 'ERR PARSING')) {
            return getters.profile.phone_number_as_e164;
        };
        const primaryContact = getters.profile?.phones?.length ? getters.profile.phones[0] : undefined
        return primaryContact && primaryContact.phone_number && primaryContact.country_code ? `+${primaryContact.country_code}${primaryContact.phone_number.split('-').join('')}` : undefined;
    },
    profilesPreferences: state => state.profilesPreferences,
    getProfilePreferences: (state) => (profileId) => state.profilesPreferences[profileId] || {},
    profilePatientJourneys: (_, getters) => getters.profile && getters.profile.patient_journeys,
    profilePrimaryContact: (_, getters) => {
        if (getters.profile && getters.profile.phone_number_as_e164 && (getters.profile.phone_number_as_e164 !== 'ERR PARSING')) {
            return getters.profile.phone_number_as_e164;
        };
        const primaryContact = getters.profile?.phones?.length ? getters.profile.phones[0] : undefined
        return primaryContact && primaryContact.phone_number && primaryContact.country_code ? `+${primaryContact.country_code}${primaryContact.phone_number.split('-').join('')}` : undefined;
    },
    getTemplateByName: (state) => (templateName) => state.consentTemplates.find(t => t.name === templateName),
};
