function setupScrollDetection(queryTarget, classToAdd) {
  const scrollContainer = document.querySelector(queryTarget);
  
  let timeout;
  scrollContainer && scrollContainer.addEventListener('scroll', () => {
    scrollContainer.classList.add(classToAdd);

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      scrollContainer.classList.remove(classToAdd);
    }, 1000);
  });
}

export default setupScrollDetection;