import { getUpperLevelDomainFromLocation } from "@/lib/urls";

class GatewayUrlBuilder {
  #_domain = undefined;

  #getDomain = () => {
    if (this.#_domain === undefined) {
      this.#_domain = getUpperLevelDomainFromLocation();
      this.#_domain = (this.#_domain === "localhost")
        ? "http://localhost:9001"
        : `https://gateway.${this.#_domain}`;
    }
    return this.#_domain;
  };

  #build = (path, queryParams = {}, fragmentParams = {}) => {
    const url = new URL(path, this.#getDomain());

    // Append query parameters
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    // Append fragment parameters
    const fragment = Object.entries(fragmentParams)
      .map(([key, value]) => value ? `${key}=${value}` : `${key}`)
      .join("&");
    if (fragment) {
      url.hash = `#${fragment}`;
    }

    return url.href;
  };

  profileDetails = (profileId) => this.#build(`/profile/${profileId}`);
  newSiteUser = (userId, siteId) => this.#build('/site-user/new', { currentUserId: userId, currentSiteId: siteId });
  addProfile = (siteId, practitionerNpi = null) => {
    const queryParams = practitionerNpi ? { referrer: practitionerNpi } : {};
    return this.#build("/nuvoair-user/new-patient", queryParams, { siteId });
  }
  aosSession = (sessionId) => this.#build(`/aos-session/${sessionId}`);
  addSpo2 = (profileId) => this.#build(`/profile/${profileId}`, { autoCreate: true }, { SPO2: null });
  careTeamMemberReport = () => this.#build(`/care-team-member-report`);
  documentViewer = (documentId) => this.#build("/admin/document-viewer", {documentId});
}

export default new GatewayUrlBuilder();