export function userName(user) {
  if (!user) return 'N/A';
  let n = user.first_name ? user.first_name : '';
  n += user.last_name ? ` ${user.last_name}` : '';
  return n.trim() || 'N/A';
}

export function userInitials(user) {
  if (!user) return 'N/A';
  let n = user.first_name ? user.first_name[0].toUpperCase() : '';
  n += user.last_name ? user.last_name[0].toUpperCase() : '';
  return n.trim() || '-';
}

// See: https://plnkr.co/edit/mQR3pafvsumGc0jl?preview
export function agGridHeaderHeightGetter() {
  const columnHeaderTexts = [...document.querySelectorAll('.ag-header-cell-text')];
  const clientHeights = columnHeaderTexts.map(headerText => headerText.clientHeight);
  return Math.max(...clientHeights);
}
