import Vue from 'vue';
import Avatar from 'primevue/avatar';
import InputText from 'primevue/inputtext';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import OverlayPanel from 'primevue/overlaypanel';
import Divider from 'primevue/divider';
import Editor from 'primevue/editor';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Tag from 'primevue/tag';
import Panel from 'primevue/panel';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import ConfirmPopup from 'primevue/confirmpopup';
import Timeline from 'primevue/timeline';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import MultiSelect from 'primevue/multiselect';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TabMenu from 'primevue/tabmenu';
import Chip from 'primevue/chip';
import PanelMenu from 'primevue/panelmenu';
import Menubar from 'primevue/menubar';
import TieredMenu from 'primevue/tieredmenu';
import Menu from 'primevue/menu';
import Toolbar from 'primevue/toolbar';
import ProgressBar from 'primevue/progressbar';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

Vue.component('Avatar', Avatar);
Vue.component('InputText', InputText);
Vue.component('Toolbar', Toolbar);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Button', Button);
Vue.component('DataTable', DataTable);
Vue.component('Dialog', Dialog);
Vue.component('Column', Column);
Vue.component('Calendar', Calendar);
Vue.component('RadioButton', RadioButton);
Vue.component('Dropdown', Dropdown);
Vue.component('Textarea', Textarea);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('Divider', Divider);
Vue.component('Editor', Editor);
Vue.component('Accordion', Accordion);
Vue.component('AccordionTab', AccordionTab);
Vue.component('Card', Card);
Vue.component('Checkbox', Checkbox);
Vue.component('InputNumber', InputNumber);
Vue.component('Badge', Badge);
Vue.directive('badge', BadgeDirective);
Vue.component('Tag', Tag);
Vue.component('Panel', Panel);
Vue.component('InputSwitch', InputSwitch);
Vue.component('SelectButton', SelectButton);
Vue.component('Timeline', Timeline);
Vue.component('InlineMessage', InlineMessage);
Vue.component('Message', Message);
Vue.component('Toast', Toast);
Vue.component('ConfirmPopup', ConfirmPopup);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('Sidebar', Sidebar);
Vue.component('Skeleton', Skeleton);
Vue.component('SplitButton', SplitButton);
Vue.component('MultiSelect', MultiSelect);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('TabMenu', TabMenu);
Vue.component('Chip', Chip);
Vue.component('PanelMenu', PanelMenu);
Vue.component('Menubar', Menubar);
Vue.component('Menu', Menu);
Vue.component('TieredMenu', TieredMenu);
Vue.component('ProgressBar', ProgressBar);

Vue.use(ConfirmationService);
Vue.use(ToastService);

Vue.directive('tooltip', Tooltip);
