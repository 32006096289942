<template>
  <div :style="selfStyle" :class="selfClass">
    <div v-if="!isMaximizedSidebar" class="sidebar-header relative">
      <slot name="sidebar-header">
        <!-- Header content -->
      </slot>
      <Button v-if="!isMaximizedSidebar" class="p-1 p-button-text absolute top-0 right-0" icon="pi pi-times" @click="close_clickHandler" />
    </div>
    <div class="sidebar-content">
      <slot name="sidebar-content">
        <!-- Main Content -->
      </slot>
    </div>
    <div class="sidebar-footer">
      <slot name="sidebar-footer">
        <!-- Footer content -->
      </slot>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

const sidebarVuex = createNamespacedHelpers('sidebar');

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...sidebarVuex.mapState(['expanded', 'maximizedId']),
    isMaximizedSidebar() {
      return this.id === this.maximizedId;
    },
    isHidden() {
      return this.payload?.hidden || (this.maximizedId && !this.isMaximizedSidebar);
    },
    selfStyle() {
      return this.width ? {minWidth: this.width + 'px', maxWidth: this.width + 'px'} : {};
    },
    selfClass() {
      return {
        'sidebar-main': true,
        'hidden': this.isHidden,
        'maximized': this.isMaximizedSidebar,
        'expanded': this.expanded,
      }
    },
  },
  methods: {
    ...sidebarVuex.mapActions(['removeSidebar', 'minimizeSidebar']),
    close_clickHandler() {
      if (this.isMaximizedSidebar) {
        this.minimizeSidebar();
      } else {
        this.removeSidebar(this.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-main {
  min-width: 400px;
  max-width: 400px;
  height: calc(100vh - 48px);
  display: none;
  flex-direction: column;
  box-shadow: 5px 0px 12px #0000000d;
  &.expanded:not(.hidden) {
    display: flex;
  }
  &.hidden {
    display: none;
  }
  &.maximized {
    padding-top: 0;
    min-width: calc(100vw - 64px);
  }
  .sidebar-header {
    .collapse-button {
      height: 52px;
      width: 52px;
      font-size: 2rem;
    }
  }
  .sidebar-footer {
    flex: 0 0 auto;
  }
  .sidebar-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}
</style>
