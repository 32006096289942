<template>
  <window-scroll-detector>
    <v-app>
      <v-main>
        <router-view />
      </v-main>
      <error-snackbar />
      <success-snackbar />
      <version />
    </v-app>
  </window-scroll-detector>
</template>

<script>
import PortalComponentMixin from "@/mixins/PortalComponentMixin";
import ErrorSnackbar from "@/components/layout/ErrorSnackbar";
import SuccessSnackbar from "@/components/layout/SuccessSnackbar";
import Version from "@/components/Version";
import WindowScrollDetector from "@/components/layout/positioning/WindowScrollDetector";
import '@/styles/base.scss';

export default {
  mixins: [PortalComponentMixin, ErrorSnackbar, SuccessSnackbar],
  components: {
    WindowScrollDetector,
    ErrorSnackbar,
    SuccessSnackbar,
    Version
  }
};
</script>
