//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
    trialQuestionnaires: [
      'st-georges-respiratory',
      'pam-13',
      'six-month-check-in',
      '3-month-check-in-respiratory-diagnosis',
      '3-month-check-in-education',
      '3-month-check-in-utilization',
      '3-month-check-in-medication'
    ],
    assignedQuestionnaires: [],
    questionnaireResults: [],
    loading: false
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  SET_PATIENT_QUESTIONNAIRES (state, questionnaires) {
    state.assignedQuestionnaires = questionnaires
  },
  POPULATE_QUESTIONNAIRE_RESULTS (state, questionnaireResult) {
    if(state.assignedQuestionnaires.find(questionnaire => questionnaire.internalName === questionnaireResult.internalName)){
      state.assignedQuestionnaires.find(questionnaire => questionnaire.internalName === questionnaireResult.internalName).results.push(questionnaireResult.result)
      state.assignedQuestionnaires.find(questionnaire => questionnaire.internalName === questionnaireResult.internalName).resultsLoaded = true
    }
  },
  CLEAR_ASSIGNED_QUESTIONNAIRES (state) {
    state.assignedQuestionnaires = []
  },
  CLEAR_QUESTIONNAIRE_RESULTS (state) {
    state.questionnaireResults = []
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  }
};

export const actions = {
  clearQuestionnaireState ({ commit }) {
    commit('CLEAR_ASSIGNED_QUESTIONNAIRES')
    commit('CLEAR_QUESTIONNAIRE_RESULTS')
  },
  async fetchPatientAssignedQuestionnaires ({ commit, dispatch }, { patientID }) {
    await dispatch('clearQuestionnaireState');
    commit ('SET_LOADING', true)
    try {
        const response = await this.$apiv2.getAssignedQuestionnaires(patientID)
        const assignedQuestionnaires = response.data
        for (const questionnaire of assignedQuestionnaires) {
          questionnaire.results = []
          questionnaire.resultsLoaded = false
        }
        commit('SET_PATIENT_QUESTIONNAIRES', assignedQuestionnaires)
        await dispatch('fetchPatientQuestionnaireAnswers', { patientID })
        commit ('SET_LOADING', false)
    }
    catch (e) {
        commit('SET_PATIENT_QUESTIONNAIRES', [])
        commit ('SET_LOADING', false)
        throw e
    }
  },
  async fetchPatientQuestionnaireAnswers ({ commit, state, dispatch }, { patientID }) {
    try {
      const response = await this.$apiv2.getAllQuestionnaireResults(patientID)
      dispatch('categorizeQuestionnaireResults', { questionnaireResults: response.data })

    }
    catch (e) {
      throw e
    }
  },
  categorizeQuestionnaireResults ({ commit, state }, { questionnaireResults }) {
    questionnaireResults.forEach(questionnaireResult => {
      commit('POPULATE_QUESTIONNAIRE_RESULTS', {result: questionnaireResult, internalName: questionnaireResult.questionnaireInternalName})
    })
  }

 
}

export const getters = {
  questionnaireByInternalName: (state) => (internalName) => {
    return state.assignedQuestionnaires.find(questionnaire => questionnaire.internalName === internalName)
  },
}