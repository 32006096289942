import slugify from "slugify";

export function slugFromSite(site) {
  return slugify(site.long_name || site.longName).toLowerCase();
}

export function slugFromSiteProfile(siteProfile) {
  const { first_name, last_name, personal_number } = siteProfile.profile;

  return slugify(`${first_name} ${last_name} ${personal_number}`).toLowerCase();
}