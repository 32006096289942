<template>
  <div class="header-logo">
    <nuxt-link exact to="/" class="d-flex">
      <img class="navbar-logo" alt="" src="../../../assets/nuvoair-logo-white.png" />
    </nuxt-link>
  </div>
</template>

<script>
import "./HeaderLogo.scss";

export default {};
</script>
