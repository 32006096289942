<template>
  <SidebarContent :id="id" :payload="payload" :width="700">
    <template v-slot:sidebar-header>
      <div>
        <div>Call Transcript</div>
        <div class="font-medium text-lg">
          <span>{{profileFullName}}, {{transcriptStart.format('YYYY-MM-DD')}}</span>
        </div>
      </div>
    </template>
    <template v-slot:sidebar-content>
      <div class="flex flex-column flex-grow-0 transcript">
        <div class="flex justify-center mt-2 text-gray-600">
          --- Start of Call ---
        </div>
        <div class="flex flex-column ma-2" v-for="entry in timeline">
          <span class="entry-header">{{displayUsers(entry.users)}} {{displayTimestamp(entry.ts)}}</span>
          <span>{{entry.text}}</span>
        </div>
      </div>
    </template>
    <template v-slot:sidebar-footer>
    </template>
  </SidebarContent>
</template>

<script>
import PortalComponentMixin from '@/mixins/PortalComponentMixin';
import SidebarContent from "@/components/layout/sidebar/SidebarContent";
import moment from 'moment';
import {setupScrollDetection} from "@/helpers";
import {createNamespacedHelpers} from 'vuex';

const sidebarVuex = createNamespacedHelpers('sidebar');

export default {
  components: {
    SidebarContent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    setupScrollDetection('.transcript','show-scrollbar');
  },
  computed: {
    ...sidebarVuex.mapState(['maximizedId']),
    isMaximized() {
      return this.id === this.maximizedId;
    },
    transcript() {
      if (this.payload?.log) {
        return JSON.parse(this.payload.log.payload);
      }
    },
    timeline() {
      return this.transcript?.timeline;
    },
    profile() {
      return this.payload?.profile;
    },
    profileFullName() {
      return this.profile ? `${this.profile.first_name || ''} ${this.profile.last_name || ''}` : undefined;
    },
    transcriptStart() {
      return moment(this.transcript.recording_start);
    },
  },
  methods: {
    ...sidebarVuex.mapActions(['closeSidebar', 'maximizeSidebar']),
    close_clickHandler() {
      this.closeSidebar(this.id)
    },
    isProfileUsersPhone(phone) {
      return this.profile?.phones.some(profilePhone => {
        return `+${profilePhone.country_code}${profilePhone.phone_number}` === phone;
      })
    },
    displayUsers(users) {
      if (!users.length) {
        return 'Unknown';
      }
      const displayNames = users.map(user => {
        if (this.isProfileUsersPhone(user.user_id)) {
          return this.profileFullName;
        } 
        return user.username;
      });
      return displayNames.join(',');
    },
    displayTimestamp(ts) {
      const timestamp = moment(this.transcriptStart);

      const elapsedParts = ts.split(':');
      const hours = parseInt(elapsedParts[0]);
      const minutes = parseInt(elapsedParts[1]);
      const seconds = parseFloat(elapsedParts[2]);

      timestamp.add({hours, minutes, seconds})
      return timestamp.format('hh:mm:ss A');
    },
    maximize_clickHandler() {
      this.maximizeSidebar(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-main {
  padding: 20px 24px 24px;
}

.transcript {
  overflow-y: auto;
}

.entry-header {
  color: #135EAE;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: transparent; 
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #609af8;
}
</style>
