<template>
  <v-dialog v-model="isShown"
            persistent
            max-width="787px">
    <v-card>
      <div v-if="saving"
           class="progress-filler">
        <v-progress-circular :size="50"
                             color="primary"
                             indeterminate />
        <h3 class="mt-4">Adding new user...</h3>
      </div>
      <v-form v-else ref="form" class="add-user-form">
        <v-card-title>
          <span class="headline">Add User</span>
        </v-card-title>
        <v-card-text>
          <v-col>
            <v-row class="mb-4">
              <v-text-field v-model="user.email"
                            autofocus="autofocus"
                            :rules="userFormEmailRules()"
                            label="Email *"
                            class="mr-4"
                            required />
              <v-switch v-model="user.portalConfiguration.twoFactorAuthentication"
                        v-if="hasPermissionInAnySite(ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS)"
                        inset
                        label="Two Factor Auth (requires phone)"/>
            </v-row>
            <v-row class="mb-4">
              <v-text-field v-model="user.firstName"
                            :rules="userFormFirstNameRules()"
                            label="First Name *"
                            class="mr-4"
                            required />
              <v-text-field v-model="user.lastName"
                            :rules="userFormLastNameRules()"
                            label="Last Name *"
                            required />
            </v-row>
            <v-row class="mb-4">
              <v-col lg="2" md="3">
                <vue-country-code
                  class="custom-phone-country-code-input"
                  :enabledCountryCode=true
                  @onSelect="countryPhoneCodeSelect"
                  :preferredCountries="['se', 'gb', 'us', 'it', 'de']">
                </vue-country-code>
              </v-col>
              <v-col lg="10" md="9">
                <v-text-field v-model="user.phoneNumber"
                  :rules="phoneNumberRules()"
                  label="Phone Number (exclude country code)" />  
              </v-col>
              
              <!-- COMMENTED UNTIL THE NEW SYSTEM IS VERIFIED WITH JOSH --sven
                <v-text-field v-model="user.phoneCountryCode"
                class="mr-4"
                :rules="phoneCountryCodeRules()"
                label="Phone Country Code (e.g. +44)" /> 
              -->
              
            </v-row>
            <v-row>
              <v-autocomplete v-model="user.siteIds"
                              :items="sitesWithPermission(ACTION_TYPE_SITE_USER_ADD)"
                              label="Add to Site(s) *"
                              item-text="short_name"
                              item-value="id"
                              deletable-chips chips multiple required
                              @change="userSitesChangeHandler">
                <template v-slot:item="{ item }">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.short_name"/>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-row>
          </v-col>
          <v-row v-if="selectedSites.length > 0">
            <v-col v-if="hasPermissionToMakeAdminInAnySelectedSite">
              <h3 class="mb-4">Permissions within Site</h3>
              <p class="caption">
                An admin has additional privileges which include inviting
                and removing users, changing users roles and modifying site
                access privileges for other users.
              </p>
              <div class="selected-site" v-for="site in this.selectedSites"
                   v-if="hasPermissionInSite(site, ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS)">
                <v-switch v-model="user.isAdminFor[site.id]"
                          inset
                          :label="`Make new user an Admin for ${site.long_name}`"/>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeClickHandler">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="addUserClickHandler" :disabled="!valid">Add User</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import PortalComponentMixin from "../../mixins/PortalComponentMixin";
  import { userFormFirstNameRules, userFormLastNameRules, userFormEmailRules, phoneCountryCodeRules, phoneNumberRules } from "../../utils/formUtil";
  import { ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS, ACTION_TYPE_PORTAL_SETTINGS_EDIT, ACTION_TYPE_SITE_USER_ADD } from "../../PortalConstants";
  import {STORE_INVALIDATE_USERS, STORE_RESET_ADD_USER_DIALOG} from "@/store/portal";
  import VueCountryCode from "vue-country-code";

  export default {
    mixins: [PortalComponentMixin],
    components: { VueCountryCode },
    data() {
      return {
        userFormFirstNameRules,
        userFormLastNameRules,
        userFormEmailRules,
        phoneCountryCodeRules,
        phoneNumberRules,
        valid: false,
        selectedSites: [],
        saving: false,
        ACTION_TYPE_SITE_USER_ADD,
        ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS,
        ACTION_TYPE_PORTAL_SETTINGS_EDIT
      }
    },
    computed: {
      isShown() {
        return this.getStorePropertySafe('portal', 'dialogs.addUser.show', false);
      },
      user() {
        return this.getStorePropertySafe('portal', 'dialogs.addUser.user', {});
      },
      hasPermissionToMakeAdminInAnySelectedSite() {
        if (this.selectedSites) {
          return !!this.selectedSites.find(site => this.hasPermissionInSite(site, ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS));
        }

        return false;
      }
    },
    methods: {
      countryPhoneCodeSelect({name, iso2, dialCode}) {
        // CHECK WITH JOSH IF WE NED A '+' OR '00' OR NONE OF THOSE
        this.user.phoneCountryCode = '+' + dialCode
      },
      validateField () {
        this.valid = this.$refs.form.validate();
      },
      closeClickHandler() {
        this.commit('portal','closeDialog', 'addUser');
      },
      addUserClickHandler() {
        this.saving = true;

        this.api.postUser(this.dialogUserToPostBody()).then(result => {
          this.saving = false;
          this.commit('portal','closeDialog', 'addUser');
        }).then(result => {
          const formUser = this.getStorePropertySafe('portal','dialogs.addUser.user');

          this.commit('portal', 'resetAddUserDialog');
          this.$refs.form.reset();
          this.commit('portal', 'invalidateUsers');
          this.commit('snackbar', 'success', {
            message: `A new user has been added and a confirmation email has been sent to them at ${formUser.email} to confirm their account.`,
            timeout: 10000
          });
        }).catch(error => {
          this.saving = false;
          this.defaultServerErrorHandler(error);
        });
      },
      userSitesChangeHandler() {
        this.selectedSites = this.user.siteIds.map(sid => this.getSiteById(sid));
      },
      getRoleSelectedForSite(siteId) {
        const formUser = this.getStorePropertySafe('portal','dialogs.addUser.user');

        return formUser.isAdminFor[siteId] ? this.getPortalAdminRole() : this.getPortalCaregiverRole();
      },
      dialogUserToPostBody() {
        const formUser = this.getStorePropertySafe('portal','dialogs.addUser.user');

        return {
            source: 'portal',
            user: {
                email: formUser.email,
                first_name: formUser.firstName,
                last_name: formUser.lastName,
                phone_number: formUser.phoneNumber || '',
                phone_country_code: formUser.phoneCountryCode || '',
                portal_configuration: {
                  two_factor_authentication: false
                },
                site_users: formUser.siteIds.map(sid => ({
                  site_id: sid,
                  roles: [this.getRoleSelectedForSite(sid)]
                }))
            }
        }
      }
    },
    watch: {
      'user': {handler: 'validateField', deep: true}
    }
  };
</script>

<style lang="scss">
  .progress-filler {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .add-user-form {
    .v-input {
      padding-top: 0;
    }
    .v-card__text {
      padding-bottom: 0px;
    }
  }
  .selected-site {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .v-input--selection-controls {
      margin-top: 0;
    }

    .site-name {
      font-weight: bold;
    }
  }

  .custom-phone-country-code-input {
    width: 100%;
    .country-code {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
</style>