<template>
  <div :class="selfClass()">
    <div class="overlay"></div>
    <div class="banner">
      <div>Your session is about to expire. You will be logged out in <strong>{{secondsUntilLogout()}}</strong> seconds.</div>
      <v-btn small @click='keepMeLoggedIn_clickHandler' color='success'>Keep me logged in</v-btn>
    </div>
  </div>
</template>

<script>
  import PortalComponentMixin from "../../mixins/PortalComponentMixin";
  import './SessionExpirationBar.scss';

  export default {
    mixins: [PortalComponentMixin],
    mounted() {
      this.interval = setInterval(() => {
        this.update();
      }, 1000);
    },
    beforeDestroy() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    methods: {
      shouldShow() {
        return this.secondsUntilLogout() <= 60 * 2; // 2 minutes before logout start showing the notice.
      },
      selfClass() {
        return 'session-expiration-bar ' + (this.shouldShow() ? 'show' : '');
      },
      secondsUntilLogout() {
        const { sessionExpirationDate } = this.$store.state.portal;

        if (sessionExpirationDate) {
          const millisecondsUntilExpiration = sessionExpirationDate.getTime() - (new Date()).getTime();

          return Math.round(millisecondsUntilExpiration / 1000);
        }

        return Number.MAX_SAFE_INTEGER;
      },
      update() {
        const { sessionExpirationDate } = this.$store.state.portal;

        if (sessionExpirationDate) {
          const millisecondsUntilExpiration = sessionExpirationDate.getTime() - (new Date()).getTime();

          if (millisecondsUntilExpiration < 0) {
            this.commit('portal', 'setSessionExpirationDate', undefined);
            this.commit('portal', 'setSessionExpired', true);
            this.commit('portal', 'resetSites');
            this.commit('portal', 'resetSettings');

            this.$auth.setUser(undefined);
          }

          if (this.shouldShow()) {
            this.$forceUpdate();
          }
        }
      },
      keepMeLoggedIn_clickHandler() {
        this.extendSession();
      }
    },
    watch: {
      '$store.state.portal.sessionExpirationDate': function() {
        this.$forceUpdate();
      }
    }
  };
</script>