<template>
  <v-breadcrumbs :items="breadcrumbs" class="ml-0 pl-0 flex-nowrap">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-btn text color="primary" :href="item.href">
        <v-icon v-if="item.icon">{{item.icon}}</v-icon>
        <span v-if="item.text" class="toolbar-page-title">{{item.text}}</span>
      </v-btn>
    </template>
  </v-breadcrumbs>
</template>

<script>
  import PortalComponentMixin from "../../mixins/PortalComponentMixin";

  export default {
    mixins: [PortalComponentMixin],
    computed: {
      breadcrumbs() {
        const breadcrumbs = [];

        if (this.isSettingsRoute) {
          breadcrumbs.push({
            'text': 'Settings',
            'href': '/settings'
          });

          const selectedSite = this.getStorePropertySafe('portal','selectedSite');

          if (selectedSite) {
            breadcrumbs.push({
              'text': selectedSite.short_name
            })
          }

          if (this.isMyPermissionsRoute) {
            breadcrumbs.push({
              'text': 'My Permissions'
            })
          }
        }

        return breadcrumbs;
      }
    }
  };
</script>