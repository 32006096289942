import Cookies from 'js-cookie';
import {parseJwt} from "@/lib/jwt";
import {getUpperLevelDomainFromLocation, isProductionDomain} from "@/lib/urls";

const SSO_TOKEN_DOMAIN = getUpperLevelDomainFromLocation();
const SSO_TOKEN_COOKIE= isProductionDomain(SSO_TOKEN_DOMAIN)
  ? 'sso_auth_token_prod'
  : 'sso_auth_token_dev';
const SSO_TOKEN_COOKIE_PATH = '/';

export const state = () => ({
  isInitialized: false
});

export const getters = {
  isInitialized: state => state.isInitialized,
  token: state => Cookies.get(SSO_TOKEN_COOKIE)
}

export const mutations = {
  setInitialized(state) {
    state.isInitialized = true;
  },

  setToken(state, token) {
    const jwt = parseJwt(token);

    const cookieParams = {
      domain: '.' + SSO_TOKEN_DOMAIN,
      path: SSO_TOKEN_COOKIE_PATH,
      expires: new Date(jwt.exp * 1000),
      secure: true,
      sameSite: 'strict'
    };

    if (cookieParams.domain === '.localhost') {
      delete cookieParams.domain;
      delete cookieParams.secure;
    }

    Cookies.set(SSO_TOKEN_COOKIE, token, cookieParams);
  },

  resetToken(state) {
    const cookieParams = {
      domain: '.' + SSO_TOKEN_DOMAIN,
      path: SSO_TOKEN_COOKIE_PATH
    };

    if (cookieParams.domain === '.localhost') {
      delete cookieParams.domain;
    }

    Cookies.remove(SSO_TOKEN_COOKIE, cookieParams);
  }
};
