<template>
  <div :class="selfClass">
    <slot />
  </div>
</template>
<script>
  import './WindowScrollDetector.scss';
  import PortalComponentMixin from '@/mixins/PortalComponentMixin';

  export default {
    mixins: [PortalComponentMixin],
    props: {
      threshold: {
        type: Number,
        default: 1 // 10 pixels of scroll required to go into fixed
      }
    },
    data() {
      return {
        scrolled: false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.window_scrollHandler);
    },
    unmounted() {
      window.removeEventListener('scroll', this.window_scrollHandler);
    },
    computed: {
      selfClass() {
        return {
          'window-scroll-detector': true,
          scrolled: this.scrolled
        };
      }
    },
    methods: {
      window_scrollHandler() {
        this.scrolled = window.scrollY > this.threshold;

        if (this.$store.state.layout.window.scrolled !== this.scrolled) {
          this.commit('layout', 'setWindowScrolled', this.scrolled);
        }
      }
    },
  }
</script>