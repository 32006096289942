<template>
  <Menubar id="header-navigation" class="header-navigation" :model="null">
    <template #start>
      <div class="flex align-items-center">
        <HeaderLogo />
        <span class="text-xl ml-2">Portal</span>
      </div>
    </template>
    <template #end>
      <Button v-if="user" class="p-button-outlined mx-2 header-search" type="button" icon="pi pi-search" label="Search Patients" @click="toggleSearchOverlay" aria:haspopup="true" aria-controls="searchOverlay" />
      <OverlayPanel v-if="user" ref="searchOverlay" appendTo="body" id="header-search-overlay" :autoZIndex="false" :dismissable="false" showCloseIcon>
        <PatientSearch @toggleOverlay="toggleSearchOverlay" @profileSelected="profile_clickHandler" applySiteAccess />
      </OverlayPanel>

      <Button v-if="user && isStaff && userIsGlobalCaregiverLead" class="p-button-outlined mx-2 header-new-patient" type="button" icon="pi pi-plus" label="New Patient" @click="toggleNewPatientOverlay" aria:haspopup="true" aria-controls="newPatientOverlay" />
      <OverlayPanel v-if="user && isStaff && userIsGlobalCaregiverLead" ref="newPatientOverlay" appendTo="body" id="header-new-patient-overlay" :dismissable="false" showCloseIcon>
        <PatientCreate @toggleOverlay="toggleNewPatientOverlay" @practitionerSelected="practitioner_clickHandler" />
      </OverlayPanel>
      <router-link to="/" class="router-link-reset">
        <Button icon="pi pi-home" rounded class="mr-2" />
      </router-link>
      <Button v-if="showUserAvatar" type="button" size="small" icon="pi pi-user" severity="secondary" rounded
        @click="toggleUserMenu" aria-haspopup="true" aria-controls="user_menu" />
      <TieredMenu v-if="showUserAvatar" ref="userMenu" id="user_menu" :model="userMenuItems" popup />
    </template>
  </Menubar>
</template>
<script>
import HeaderLogo from "./HeaderLogo.vue";
import PortalComponentMixin from "@/mixins/PortalComponentMixin";
import { userInitials } from "@/utils/agGridUtil";
import { createNamespacedHelpers } from 'vuex';
import PatientSearch from "@/components/common/PatientSearch";
import PatientCreate from "@/components/common/PatientCreate";
import { slugFromSite } from "@/utils/modelUtil";

const portalVuex = createNamespacedHelpers('portal');

export default {
  mixins: [PortalComponentMixin],
  props: {
    id: {
      type: String,
      default: "header"
    }
  },
  components: {
    HeaderLogo,
    PatientSearch,
    PatientCreate
  },
  data () {
    return {
      userInitials,
      user: this.$auth.user,

    }
  },
  computed: {
    ...portalVuex.mapState(['sites']),
    ...portalVuex.mapGetters(['isStaff', 'gatewayNewPatientUrl', 'userHasGlobalRole']),
    initials () {
      return userInitials(this.user);
    },
    userMenuItems () {
      return [
        {
          label: 'Logged in as ' + this.userFullName,
          disabled: true
        },
        {
          separator: true
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: this.logoutClickHandler
        },
        {
          label: 'Change Password',
          icon: 'pi pi-key',
          url: '/account/password/reset'
        },
        // Since it has v-if="false", I assume it shouldn't show up, so I'm excluding it here.
        // {
        //     label: 'My Permissions',
        //     url: '/settings/my-permissions'
        // },
        {
          label: 'Debug Harnesses',
          icon: 'pi pi-cog',
          url: '/debug',
          condition: this.userIsEmployee
        },
        {
          label: 'User Manual',
          icon: 'pi pi-book',
          url: this.manualUrl,
          target: '_blank',
          condition: this.manualUrl
        }
      ];
    },
    userIsGlobalCaregiverLead() {
      return this.userHasGlobalRole("caregiver_lead");
    },
    showUserAvatar () {
      return this.user && this.user.account_confirmed && !this.user.force_password_reset_on_login;
    },
    userIsEmployee () {
      return this.user && this.user.email.endsWith("nuvoair.com");
    },
    userFullName () {
      return this.user ? this.user.first_name + ' ' + this.user.last_name : '';
    }
  },
  methods: {
    toggleUserMenu (event) {
      this.$refs.userMenu.toggle(event);
    },
    logoutClickHandler() {
      this.$auth
        .logout()
        .then(result => {
          this.commit('portal', 'resetSites');
          this.commit('portal', 'resetSettings');
          this.commit('sso', 'resetToken');
          this.$router.push({ path: "/account/login" }); // Hack because auth nuxt redirects are not working properly
        });
    },
    toggleSearchOverlay(event) {
      this.$refs.searchOverlay.toggle(event);
      setTimeout(() => {
        this.$refs.searchOverlay && this.fixOverlay('header-search-overlay');
      },500);
    },
    toggleNewPatientOverlay(event) {
      this.$refs.newPatientOverlay.toggle(event);
      setTimeout(() => {
        this.$refs.newPatientOverlay && this.fixOverlay('header-new-patient-overlay');
      },500);
    },
    navigateToSiteProfile(siteProfile) {
      this.$router.push({ path: `/site/${slugFromSite(siteProfile.site)}/patient/${siteProfile.id}` });
    },
    siteProfilesUserCanAccess(siteProfiles) {
      return siteProfiles.filter(sp => this.sites.some(site => site.id && site.id === sp.site?.id));
    },
    profile_clickHandler(profile) {
      const accessibleSiteProfiles = this.siteProfilesUserCanAccess(profile.siteProfiles);
      accessibleSiteProfiles.length && this.navigateToSiteProfile(accessibleSiteProfiles[0]);
      accessibleSiteProfiles.length && this.toggleSearchOverlay(false);
    },
    practitioner_clickHandler({practitioner, site}) {
      const newPatientUrl = this.gatewayNewPatientUrl(site.id, practitioner.npi);
      window.open(newPatientUrl, '_blank');
    },
    fixOverlay (overlayId) {
      const overlay = document.getElementById(overlayId);
      const header = document.getElementById('header-navigation');
      const headerRect = header.getBoundingClientRect();
      if (overlay) {
        overlay.style.position = 'fixed';
        overlay.style.top = headerRect.height + 'px';
      }
    }
  },
  watch: {
    "$store.state.auth.user": function(user) {
      this.user = user;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../styles/variables.scss';

.header-navigation {
  padding: 8px 16px;
  color: white;
  background: $color-primary;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 4rem;
  ::v-deep 
  .p-menubar-button {
    display: none;  
  }
}

.header-search, .header-new-patient {
  color: white;
  border: none;
  &.p-button.p-button-outlined:hover {
    color: white;
  }
}
#header-search-overlay, #header-new-patient-overlay {
  z-index: 200;
  ::v-deep .p-overlaypanel-close {
    background-color: transparent;
    color: grey;
    top: 11px;
    right: 12px;
  }
}
</style>