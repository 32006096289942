//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  showPhoneNumberForProfile: undefined,
  startVideoCallForProfileAggregate: undefined,
  showRelativeDateForSession: undefined
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  setShowPhoneNumberForProfile: function(state, profile) {
    state.showPhoneNumberForProfile = profile;
  },
  setStartVideoCallForProfileAggregate: function(state, profileAggregate) {
    state.startVideoCallForProfileAggregate = profileAggregate;
  },
  setShowRelativeDateForSession: function(state, isRelative) {
    state.showRelativeDateForSession = isRelative;
  }
};
