export default async function (context) {
  if (!process.client) return; // Run only on client side
  const { store, app } = context;

  const isInitialized = store.getters['sso/isInitialized'];
  if (!isInitialized) {
    // If app not initialized yet, try to get the token from cookie

    const ssoToken = store.getters['sso/token'];
    const existingToken = app.$auth.strategy.token.get();

    if (ssoToken && (!existingToken || !existingToken.includes(ssoToken))) {
      // Set the token for the auth module and fetch user
      console.log('Using SSO token found in cookie to authenticate'); // eslint-disable-line no-console

      // Mark as initialized to prevent running this logic again
      store.commit('sso/setInitialized');

      // Reset the token to avoid infinite loop in case of invalid token
      store.commit('sso/resetToken');

      await app.$auth.strategy.token.set(ssoToken);
      await app.$auth.strategy.fetchUser();
    }
  }
}