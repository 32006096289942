export const ASTHMA_REPORT_SET_DATE_RANGE_OVERLAY_OPEN = 'setDateRangeOverlayOpen';
export const ASTHMA_REPORT_SET_STATE = 'setState';
export const ASTHMA_REPORT_SET_FIX_TO = 'fixTo';
export const ASTHMA_REPORT_ATTACH_SPIROMETRY_REPORTS = 'attachSpirometryReports';
export const ASTHMA_REPORT_ATTACH_SPIROMETRY_REPORTS_2019 = 'attachSpirometryReports2019';
export const ASTHMA_REPORT_ATTACH_PRE_POST_REPORT = 'attachPrePostReports';
export const ASTHMA_REPORT_ATTACH_PRE_POST_REPORT_2019 = 'attachPrePostReports2019';
export const ASTHMA_REPORT_RESET = 'reset';
export const ASTHMA_REPORT_SET_ACQ_SCORE = 'setACQScore';
export const ASTHMA_REPORT_SET_ACQ_DATE = 'setACQDate';
export const ASTHMA_REPORT_SET_STORED_FILTERS = 'storeFilters';

export const ASTHMA_REPORT_ACTION_SET_FROM_RESPONSE = 'setFromResponse';

export const ASTHMA_REPORT_MODE_EDIT = 'edit';
export const ASTHMA_REPORT_MODE_CREATE = 'create';
export const ASTHMA_REPORT_MODE_VIEW = 'view';

export const ASTHMA_REPORT_STATE_DRAFT = 'draft';
export const ASTHMA_REPORT_STATE_FINAL = 'final';

export const ASTHMA_REPORT_ACQ_EMPTY =  [undefined, undefined, undefined];

export const ASTHMA_REPORT_EMPTY_ACQ_STATE = {
  dates: ASTHMA_REPORT_ACQ_EMPTY,
  waking: {
    scores: ASTHMA_REPORT_ACQ_EMPTY
  },
  symptoms: {
    scores: ASTHMA_REPORT_ACQ_EMPTY
  },
  activities: {
    scores: ASTHMA_REPORT_ACQ_EMPTY
  },
  shortnessOfBreath: {
    scores: ASTHMA_REPORT_ACQ_EMPTY
  },
  wheezing: {
    scores: ASTHMA_REPORT_ACQ_EMPTY
  },
  bronchodilator: {
    scores: ASTHMA_REPORT_ACQ_EMPTY
  },
  fev1Prebronchodilator: {
    scores: ASTHMA_REPORT_ACQ_EMPTY
  },
  result: {
    scores: ASTHMA_REPORT_ACQ_EMPTY
  }
};

export const ASTHMA_REPORT_EMPTY_SPIROMETRY_STATE = {
  showPercents: false,
  showHighLowTable: true,
}

export const ASTHMA_REPORT_EMPTY_AOS_STATE = {
  enabled: true
}

export const ASTHMA_REPORT_EMPTY_AQI_STATE = {
  enabled: true
}