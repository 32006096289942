export const MAX_TESTS_RENDERED = 8;
export const FIELD_STATUS_USABLE = 'usable';
export const FIELD_STATUS_UNUSABLE = 'unusable';
export const FIELD_STATUS_UNUSABLE_REPLACEMENT = '--';

export const SPIROMETRY_FLOW_CHART_DEFAULT_CHART_DATA = {
  data: {
    xs: {},
    columns: [],
    type: 'spline',
    types: {
      polygon: 'area'
    }
  },
  axis: {
    x: {
      label: {
        text: 'Volume (L)',
        position: 'outer-center'
      },
      tick: {},
      min: 0,
      padding: {
        left: 0,
        right: 0
      }
    },
    y: {
      label: {
        text: 'Flow (L/s)',
        position: 'outer-middle'
      },
      tick: {},
      padding: {
        top: 0,
        bottom: 0
      }
    }
  },
  grid: {
    lines: {
      front: false
    },
    x: {
      lines: []
    },
    y: {
      lines: []
    }
  },
  tooltip: {
    show: false
  },
  legend: {
    hide: true
  },
};

export const SPIROMETRY_VOLUME_CHART_DEFAULT_CHART_DATA = {
  data: {
    xs: {
      'best-test-volume': 'xBestVolume'
    },
    columns: []
  },
  axis: {
    x: {
      label: {
        text: 'Time (s)',
        position: 'outer-center'
      },
      tick: {},
      min: 0,
      padding: {left: 0, right: 0}
    },
    y: {
      label: {
        text: 'Volume (L)',
        position: 'outer-middle'
      },
      tick: {},
      min: 0,
      padding: {top: 0, bottom: 0}
    }
  },
  grid: {
    lines: {
      front: false
    },
    x: {
      lines: []
    },
    y: {
      lines: []
    }
  },
  legend: {
    hide: true
  },
  tooltip: {
    show: false
  },
  colors: {
    'best-test-volume': '#0073E6'
  },
};

export const SPIROMETRY_Z_SCORE_CHART_DEFAULT_CHART_DATA = {
  data: {
    columns: [
      ['Z-Score: Best', 1, 1.4, 0.2]
    ],
    type: 'line'
  },
  bar: {
    width: {
      ratio: 0.5 // this makes bar width 50% of length between ticks
    }
  },
  axis: {
    rotated: true,
    x: {
      type: 'category',
      max: 2
    },
    y: {
      tick: {
      },
      min: -6,
      max: 3,
      padding: {
        left: 0,
        right: 0
      }
    }
  },
  grid: {
    y: {
      lines: [
        {value: 0, text: 'Pred.', position: 'end'},
        // LLN (Lower Limit of Normal) is fixed to -1.64, and is the industry standard for not normal.
        {value: -1.64, text: 'LLN', position: 'end'}
      ]
    }
  },
  legend: {
    hide: true
  },
}