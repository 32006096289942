import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIMEZONE,
  formatDate,
} from '../utils/dateUtil';
//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  weightUnit: 'kg',
  heightUnit: 'cm',
  temperatureUnit: 'celsius',
  dateFormat: DEFAULT_DATE_FORMAT,
  dateTimeFormat: DEFAULT_DATE_TIME_FORMAT
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  setWeightUnit: function(state, value) {
    state.weightUnit = ['kg', 'kgs', 'kilograms'].indexOf(value) > -1 ? 'kg' : 'lbs';
  },
  setHeightUnit: function(state, value) {
    state.heightUnit = ['cm', 'centimeters', 'cms', 'm', 'meters'].indexOf(value) > -1 ? 'cm' : 'in';
  },
  setTemperatureUnit: function(state, value) {
    state.temperatureUnit = value;
  },
  setDateFormat: function(state, value) {
    state.dateFormat = value;
  },
  setDateTimeFormat: function(state, value) {
    state.dateTimeFormat = value;
  }
};

export const getters = {
  formattedDate: state => (dateOrString, formatOverride = undefined, ianaTimezoneOverride = undefined) => {
    if (!dateOrString) {
      return 'N/A';
    }
  
    let format = DEFAULT_DATE_FORMAT;
  
    if (formatOverride) {
      format = formatOverride;
    } else if (state.dateFormat) {
      format = state.dateFormat;
    }
  
    return formatDate(dateOrString, format, ianaTimezoneOverride || DEFAULT_TIMEZONE);
  },
  formattedHeight: state => (heightInCm) => {
    if (state.heightUnit === 'in') {
      function toFeet(n) {
        const realFeet = ((n*0.393700) / 12);
        let feet = Math.floor(realFeet);
        let inches = Math.round((realFeet - feet) * 12);

        // Fix for CP-343
        if (inches === 12) {
          feet++;
          inches = 0;
        }

        return feet + "' " + inches + '"';
      }

      return toFeet(heightInCm);
    }

    return heightInCm;
  },
  formattedWeight: state => (weightInKg) => {
    if (state.weightUnit === 'lbs') {
      return Math.round(weightInKg * 2.20462) + ' lbs';
    }

    return Math.round(weightInKg) + ' kg';
  },
}