import moment from 'moment';

function formatDateRange(startDate, endDate, format = 'll') {
  if (!startDate || !endDate) return '';

  const formattedStartDate = moment(startDate).format(format);
  const formattedEndDate = moment(endDate).format(format);

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export default formatDateRange;
