import moment from 'moment-timezone';
import Vue from 'vue';

export default ({ app }, inject) => {
    // Constants
    const DEFAULT_LOCALE = 'en-US';
    const ALTERNATE_LOCALE = 'en-GB';
    
    // Determine the user's locale
    const userLocale = [window.navigator.userLanguage, window.navigator.language].find(Boolean) || DEFAULT_LOCALE;
    const momentLocale = userLocale.startsWith(ALTERNATE_LOCALE) ? ALTERNATE_LOCALE : DEFAULT_LOCALE;

    // Configure PrimeVue Calendar's date format
    const dateFormat = momentLocale === ALTERNATE_LOCALE ? 'dd M yy' : 'M dd, yy';
    Vue.prototype.$dateFormat = dateFormat;

    // Set the locale for the moment library
    moment.locale(momentLocale);

    // Inject the configured moment instance globally
    inject('moment', moment);
};
