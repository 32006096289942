//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  window: {
    scrolled: false,
    fixedElementIds: []
  }
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  setWindowScrolled: function(state, value) {
    state.window.scrolled = value;
  },
  addFixedElementId: function (state, elementId) {
    const arr = state.window.fixedElementIds.concat();
    arr.push(elementId);
    state.window.fixedElementIds = arr;
  },
  removeFixedElementId: function (state, elementId) {
    const arr = state.window.fixedElementIds.concat();
    arr.splice(arr.indexOf(elementId), 1);
    state.window.fixedElementIds = arr;
  }
};