export function copyToClipboard (text) {
    if (navigator.clipboard) {
        // Clipboard API is available
        return navigator.clipboard.writeText(text);
    } else if (window.document.execCommand) {
        // Try using execCommand as a fallback
        return new Promise((resolve, reject) => {
            var input = document.createElement('input');
            input.value = text;
            document.body.appendChild(input);
            input.select();
            try {
                document.execCommand('copy');
                resolve();
            } catch (err) {
                reject(err);
            } finally {
                document.body.removeChild(input);
            }
        });
    } else {
        return Promise.reject(new Error('No suitable method found to copy text to clipboard'));
    }
}
