<template>
  <v-snackbar :value='errors.length'
              class='error-snackbar'
              color="#CC0000"
              :timeout="-1">
    <div v-for='error in errors' :key='error.time'>
      {{ error.error }}
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn color="white"
             text
             v-bind="attrs"
             @click="closeSnackbar()">
        Dismiss
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import PortalComponentMixin from "../../mixins/PortalComponentMixin";
  import './ErrorSnackbar.scss';

  export default {
    mixins: [PortalComponentMixin],
    data() {
      return {
        errors: []
      }
    },
    methods: {
      closeSnackbar() {
        this.commit('snackbar' , 'clearErrors');
      }
    },
    watch: {
      '$store.state.snackbar.errors': function() {
        this.errors = this.getStorePropertySafe('snackbar','errors', []);
      }
    }
  };
</script>