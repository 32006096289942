<template>
  <window-scroll-detector>
    <Toast position="bottom-left" />
    <v-app>
      <core-data-loader>
        <header-app-bar id="header" />
        <subheader-toolbar />
        <position-below-fixed>
          <v-main :class="selfClass">
            <router-view />
          </v-main>
        </position-below-fixed>
        <Sidebar v-if="includeSidebarInLayout" />
      </core-data-loader>
      <!--      <add-user-dialog/>-->
      <!--      <edit-user-dialog />-->
      <error-snackbar />
      <success-snackbar />
      <version />
      <session-expiration-bar />
    </v-app>
  </window-scroll-detector>
</template>

<script>
import HeaderAppBar from "@/components/layout/header/HeaderAppBar";
import CoreDataLoader from "@/components/loaders/CoreDataLoader";
import SubheaderToolbar from "@/components/layout/SubheaderToolbar";
import AddUserDialog from "@/components/dialogs/AddUserDialog";
import EditUserDialog from "@/components/dialogs/EditUserDialog";
import ErrorSnackbar from "@/components/layout/ErrorSnackbar";
import SuccessSnackbar from "@/components/layout/SuccessSnackbar";
import Sidebar from "@/components/layout/sidebar/Sidebar";
import Version from "@/components/Version";

import '@/styles/base.scss';
import WindowScrollDetector from "@/components/layout/positioning/WindowScrollDetector";
import PositionBelowFixed from "@/components/layout/positioning/PositionBelowFixed";

import './default.scss';
import SessionExpirationBar from '@/components/layout/SessionExpirationBar'
import { createNamespacedHelpers } from 'vuex'

const portalVuex = createNamespacedHelpers('portal')

export default {
  name: "default",
  mixins: [ErrorSnackbar, SuccessSnackbar],
  middleware: ['userNoPortalAccessCheck'],
  components: {
    SessionExpirationBar,
    PositionBelowFixed,
    WindowScrollDetector,
    EditUserDialog,
    ErrorSnackbar,
    HeaderAppBar,
    CoreDataLoader,
    SubheaderToolbar,
    AddUserDialog,
    SuccessSnackbar,
    Sidebar,
    Version
  },
  beforeMount () {
    document.addEventListener('mousedown', this.documentMouseDownHandler)
  },
  mounted () {
    if (this.$auth && this.$auth.user) {
      this.setPortalUser(this.$auth.user);
    }
  },
  computed: {
    ...portalVuex.mapGetters(['includeSidebarInLayout']),
    selfClass() {
      return this.includeSidebarInLayout ? "with-sidebar" : "";
    },
  },
  methods: {
    ...portalVuex.mapMutations([
      'setPortalUser'
    ]),
    documentMouseDownHandler (event) {
      if (event.target.classList[0] === 'v-overlay__scrim') {
        this.commit('portal', 'closeAllDialogs');
      }
    }
  }
};
</script>
