<template>
  <v-dialog v-model="isShown"
            persistent
            max-width="787px">
    <v-card>
      <div v-if="saving || !isShown"
           class="progress-filler">
        <v-progress-circular :size="50"
                             color="primary"
                             indeterminate/>
        <h3 class="mt-4">Saving User...</h3>
      </div>
      <v-form v-else ref="form" class="edit-user-form">
        <v-card-title>
          <span class="headline">Edit User</span>
        </v-card-title>
        <v-card-text>
          <v-col>
            <v-row class="mb-4" v-if="!user.accountConfirmed">
              <v-alert color="red lighten-4">This use has not confirmed their account email. They need to click the link
                found in their welcome email to verify. They will not have permissions within the portal until they do
                so.
              </v-alert>
            </v-row>
            <v-row class="mb-4">
              <v-text-field v-model="user.email"
                            :rules="userFormEmailRules()"
                            class="mr-4"
                            label="Email *"
                            required/>
              <v-switch v-model="user.portalConfiguration.twoFactorAuthentication"
                        v-if="hasPermissionInAnySite(ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS)"
                        inset
                        label="Two Factor Auth (requires phone)"/>
            </v-row>
            <v-row class="mb-4">
              <v-text-field autofocus="autofocus"
                            v-model="user.firstName"
                            :rules="userFormFirstNameRules()"
                            label="First Name *"
                            class="mr-4"
                            required/>
              <v-text-field v-model="user.lastName"
                            :rules="userFormLastNameRules()"
                            label="Last Name *"
                            required/>
            </v-row>
            <v-row class="mb-4">
              <v-col lg="2" md="2" sm="2">
                <!-- TODO: make defaultCountry user.phoneCountryCode.name (for example 'us' not '+1') and send the actual code as user.phoneCountryCode.code, adjust the api accordingly. If too big of an issue look for an alternative (fork the repo and fix it ourselves, seems pretty easy). -->
                <vue-country-code
                    class="custom-phone-country-code-input"
                    :enabledCountryCode=true
                    :defaultCountry="selectedCountryCode.iso2"
                    :enabledFlags=false
                    @onSelect="countryPhoneCodeSelect"
                    :preferredCountries="['se', 'gb', 'us', 'it', 'de']">
                </vue-country-code>
              </v-col>
              <v-col lg="10" md="10" sm="10">
                <v-text-field v-model="user.phoneNumber"
                              :rules="phoneNumberRules()"
                              label="Phone Number (exclude country code)"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete v-model="user.siteIdsWithPermissions"
                                v-if="sitesWithPermission(ACTION_TYPE_SITE_USER_ADD).length"
                                :items="sitesWithPermission(ACTION_TYPE_SITE_USER_ADD)"
                                label="Sites"
                                item-text="short_name"
                                item-value="id"
                                deletable-chips chips multiple required>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="item.short_name"/>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div>
                  <p class="caption">This user exists in these sites as well, but you do not have permission to make changes:</p>
                  <v-chip class="mr-4" v-for="site in user.siteIdsWithoutPermissions.map(sid => getSiteById(sid))"
                            v-bind:key="id">{{site.short_name}}</v-chip>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-row v-if="user.siteIdsWithPermissions.length > 0 && hasPermissionToMakeAdminInAnySelectedSite()">
            <v-col>
              <h3 class="mb-4 mt-4">Roles</h3>
              <p class="caption">
                An admin has additional privileges which include inviting
                and removing users, changing users roles and modifying site
                access privileges for other users.
              </p>
              <div class="selected-site" v-for="site in this.sitesWithPermissions"
                   v-if="hasPermissionInSite(site, ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS)">
                <v-switch v-model="user.isAdminFor[site.id]"
                          inset
                          :label="`Admin for ${site.long_name}`"/>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="blue darken-1" text @click="closeClickHandler">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveClickHandler" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import PortalComponentMixin from "../../mixins/PortalComponentMixin";
  import {
    phoneCountryCodeRules,
    phoneNumberRules,
    userFormEmailRules,
    userFormFirstNameRules,
    userFormLastNameRules
  } from "@/utils/formUtil";
  import {
    ACTION_TYPE_PORTAL_SETTINGS_EDIT,
    ACTION_TYPE_SITE_USER_ADD,
    ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS
  } from "@/PortalConstants";
  import VueCountryCode from "vue-country-code";
  import allCountries from "../../lib/AllCountries";
  import clone from 'clone';

  import './EditUserDialog.scss';

  export default {
    mixins: [PortalComponentMixin],
    components: {VueCountryCode},
    data() {
      return {
        userFormFirstNameRules,
        userFormLastNameRules,
        userFormEmailRules,
        phoneCountryCodeRules,
        phoneNumberRules,
        valid: false,
        user: {},
        saving: false,
        ACTION_TYPE_SITE_USER_ADD,
        ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS,
        ACTION_TYPE_PORTAL_SETTINGS_EDIT
      }
    },
    computed: {
      isShown() {
        return this.getStorePropertySafe('portal','dialogs.editUser.show', false);
      },
      sitesWithPermissions() {
        return this.user.siteIdsWithPermissions.map(sid => this.getSiteById(sid));
      },
      selectedCountryCode() {
        return this.user.phoneCountryCode ? (allCountries.find(x => x.dialCode === this.user.phoneCountryCode.slice(1, this.user.phoneCountryCode.length)) || {}) : '';
      },
      editingMyself() {
        return this.user.id === this.getMe().id;
      }
    },
    methods: {
      countryPhoneCodeSelect({name, iso2, dialCode}) {
        this.user.phoneCountryCode = '+' + dialCode;
      },
      validateField() {
        if (this.$refs.form) {
          this.valid = this.$refs.form.validate();
        }
      },
      hasPermissionToMakeAdminInAnySelectedSite() {
        return !!this.sitesWithPermissions.find(site => this.hasPermissionInSite(site, ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS));
      },
      closeClickHandler() {
        this.commit('portal', 'closeDialog', 'editUser');
      },
      saveClickHandler() {
        this.saving = true;

        this.api.patchUser(this.dialogUserToPatchBody()).then(result => {
          this.commit('portal', 'closeDialog', 'editUser');
          this.saving = false;
        }).then(result => {
          this.commit('portal', 'invalidateUsers');
          if (this.editingMyself) {
            // TODO! Fix me need a different way to invalidate the current user
            this.commit('portal', 'invalidateMe');
          }
          this.commit('portal', 'pushSuccessSnackbar', { message: `User has been updated.`, timeout: 5000 });
        }).catch(error => {
          this.defaultServerErrorHandler(error);
          this.saving = false;
        });
      },
      getRoleSelectedForSite(siteId) {
        return this.user.isAdminFor[siteId] ? this.getPortalAdminRole() : this.getPortalCaregiverRole();
      },
      dialogUserToPatchBody() {
        const { siteIdsWithPermissions, siteIdsWithoutPermissions } = this.user;
        const allSiteUsersEdited = [...siteIdsWithPermissions, ...siteIdsWithoutPermissions].map(sid => ({
          site_id: sid,
          roles: [this.getRoleSelectedForSite(sid)]
        }));

        return {
          source: 'portal',
          user: {
            id: this.user.id,
            email: this.user.email,
            first_name: this.user.firstName,
            last_name: this.user.lastName,
            phone_number: this.user.phoneNumber || '',
            phone_country_code: this.user.phoneCountryCode || '',
            portal_configuration: {
              two_factor_authentication: false
            },
            site_users: allSiteUsersEdited
          }
        }
      }
    },
    watch: {
      'user': {
        handler: 'validateField',
        deep: true
      },
      'isShown': function () {
        // We need to clone this object, and then modify locally so that we can discard the changes easily and also
        // because we are not allowed to change objects on the store without using a mutation.
        this.user = clone(this.getStorePropertySafe('portal','dialogs.editUser.user', {}));
      }
    }
  };
</script>
