import 'moment-timezone';

import * as am4core from "@amcharts/amcharts4/core";

//-----------------------------------------------------------------------------
// TIMELINE CHART TAGS
//-----------------------------------------------------------------------------
export const TIMELINE_SOURCE_TAG_COPD = 'timeline-source-tag-copd-action-plan';
export const TIMELINE_SOURCE_TAG_SPIROMETRY = 'timeline-source-tag-spirometry';
export const TIMELINE_SOURCE_TAG_CF_QUESTIONNAIRE = 'timeline-source-tag-cf-questionnaire';
export const TIMELINE_SOURCE_TAG_AQI = 'timeline-source-tag-aqi';
export const TIMELINE_SOURCE_TAG_STEPS = 'timeline-source-tag-steps';
export const TIMELINE_SOURCE_TAG_SPO2 = 'timeline-source-tag-spo2';
export const TIMELINE_SOURCE_TAG_BLOOD_PRESSURE = 'timeline-source-tag-blood-pressure';
export const TIMELINE_SOURCE_TAG_HEART_RATE = 'timeline-source-tag-heart-rate';
export const TIMELINE_SOURCE_TAG_DYSPNEA = 'timeline-source-tag-breathless-dyspnea';
export const TIMELINE_SOURCE_TAG_WEIGHT = 'timeline-source-tag-weight';
export const TIMELINE_SOURCE_TAG_HEIGHT = 'timeline-source-tag-height';
export const TIMELINE_SOURCE_TAG_MOOD = 'timeline-source-tag-mood';
export const TIMELINE_SOURCE_TAG_ASTHMA_STATUS_GINA = 'timeline-source-tag-asthma-gina';
export const TIMELINE_SOURCE_TAG_AOS = 'timeline-source-tag-aos';
export const TIMELINE_SOURCE_TAG_CAT = 'timeline-source-tag-copd-assessment-test';
export const TIMELINE_SOURCE_TAG_FRAILTY = 'timeline-source-tag-frail-scale';
export const TIMELINE_SOURCE_TAG_SELF_EFFICACY = 'timeline-source-tag-self-efficacy-chronic-disease';

export const TIMELINE_SOURCE_TAGS = [
  TIMELINE_SOURCE_TAG_COPD,
  TIMELINE_SOURCE_TAG_SPIROMETRY,
  TIMELINE_SOURCE_TAG_CF_QUESTIONNAIRE,
  TIMELINE_SOURCE_TAG_AQI,
  TIMELINE_SOURCE_TAG_STEPS,
  TIMELINE_SOURCE_TAG_SPO2,
  TIMELINE_SOURCE_TAG_BLOOD_PRESSURE,
  TIMELINE_SOURCE_TAG_HEART_RATE,
  TIMELINE_SOURCE_TAG_DYSPNEA,
  TIMELINE_SOURCE_TAG_WEIGHT,
  TIMELINE_SOURCE_TAG_HEIGHT,
  TIMELINE_SOURCE_TAG_MOOD,
  TIMELINE_SOURCE_TAG_ASTHMA_STATUS_GINA,
  TIMELINE_SOURCE_TAG_AOS,
  TIMELINE_SOURCE_TAG_CAT,
  TIMELINE_SOURCE_TAG_FRAILTY,
  TIMELINE_SOURCE_TAG_SELF_EFFICACY,
];

//-----------------------------------------------------------------------------
// SESSION GRADING
//-----------------------------------------------------------------------------
export const SESSION_GRADING_TO_BULLET_COLOR = {
    A: am4core.color("#00A560"),
    B: am4core.color("#00A560"),
    C: am4core.color("#FFAB00"),
    D: am4core.color("#FFAB00"),
    E: am4core.color("#E52817"),
    F: am4core.color("#E52817")
}

export const SESSION_GRADING_TO_CLASS = {
    A: 'good',
    B: 'good',
    C: 'average',
    D: 'average',
    E: 'poor',
    F: 'poor'
}

//-----------------------------------------------------------------------------
// QUESTIONNAIRE RESPONSES
//-----------------------------------------------------------------------------
export const DYSPNEA_RESPONSES = [
  'I only get breathless with strenuous exercise', 
  'I get short of breath when hurrying on level ground or walking up a slight hill', 
  'On level ground, I walk slower than people of the same age because of breathlessness or have to stop for breath when walking at my own pace', 
  'I stop for breath after walking about 100 yards (91 m) or after a few minutes on level ground', 
  'I am too breathless to leave the house or I am breathless when dressing.'
]


//-----------------------------------------------------------------------------
// VUEX STORE
//-----------------------------------------------------------------------------
export const TIMELINE_SET_PROFILE = 'setProfile';
export const TIMELINE_SET_SITE_PROFILE = 'setSiteProfile';
export const TIMELINE_SET_DATE_RANGE = 'setDateRange';
export const TIMELINE_SET_SHOW_CUSTOM_DATE_RANGE_PICKER = 'setShowCustomDateRangePicker';
export const TIMELINE_SET_CURSOR_DATE = 'setCursorDate';
export const TIMELINE_SET_CUR_SPIROMETRY_SESSION = 'setCurSpirometrySession';

export const TIMELINE_CLEAR_DATA_BY_SOURCE_TAG = 'clearDataBySourceTag';
export const TIMELINE_FORCE_REFRESH_BY_SOURCE_TAG = 'forceRefreshBySourceTag';
export const TIMELINE_SET_DATA_BY_SOURCE_TAG = 'setDataBySourceTag';
export const TIMELINE_SET_LOADING_BY_SOURCE_TAG = 'setLoadingBySourceTag';
export const TIMELINE_SET_SHOW_GRID_BY_SOURCE_TAG = 'setShowGridSourceTag';

// Navigation between new spirometry sessions
export const TIMELINE_NEXT_SPIROMETRY_SESSION = 'nextSpirometrySession';
export const TIMELINE_PREV_SPIROMETRY_SESSION = 'prevSpirometrySession';
export const TIMELINE_FIRST_SPIROMETRY_SESSION = 'firstSpirometrySession';
export const TIMELINE_LAST_SPIROMETRY_SESSION = 'lastSpirometrySession';

export const TIMELINE_SET_SHOW_REPORTS = 'setShowReports';

export const DATE_RANGE_NAMES = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
  ONE_WEEK: '1week',
  ONE_MONTH: '1m',
  THREE_MONTHS: '3m',
  SIX_MONTHS: '6m',
  ONE_YEAR: '1y',
  ALL: 'all',
};

export const DATE_RANGE_FILTER_LABELS = {
  [DATE_RANGE_NAMES.ONE_WEEK]: 'Last Week',
  [DATE_RANGE_NAMES.ONE_MONTH]: 'Last Month',
  [DATE_RANGE_NAMES.THREE_MONTHS]: 'Last 3 Months',
  [DATE_RANGE_NAMES.SIX_MONTHS]: 'Last 6 Months',
  [DATE_RANGE_NAMES.ONE_YEAR]: 'Last Year',
  [DATE_RANGE_NAMES.ALL]: 'All',
};
