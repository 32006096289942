//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  smsEnabled: true,
  emailsEnabled: false,
  pushNotificationsEnabled: false,
  mtsMarketingEnabled: false,
  mtsPatientCareEnabled: false,
  mtsMedicalResearchEnabled: false,
  mtsAccount: false
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  updateCommunicationPreferences: function(state, value) {
    state.smsEnabled = value.smsEnabled;
    state.emailsEnabled = value.emailsEnabled;
    state.pushNotificationsEnabled = value.pushNotificationsEnabled;
    state.mtsMarketingEnabled = value.mtsMarketingEnabled;
    state.mtsPatientCareEnabled = value.mtsPatientCareEnabled;
    state.mtsMedicalResearchEnabled = value.mtsMedicalResearchEnabled;
    state.mtsAccount = value.mtsAccount;
  }
};
