<template>
  <div :class="calcClass">
    <div class="content" :class="pageWrapper ? 'mb-8' : ''">
      <slot />
    </div>
    <div class="progress" v-if="loading">
      <h3 v-if="text">{{ text }}</h3>
      <v-progress-circular size='20' indeterminate color="primary" />
    </div>
  </div>
</template>
<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    pageWrapper: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    calcClass () {
      return 'progress-container' + (this.loading ? ' loading' : '') + (this.classes ? ' ' + this.classes : '');
    }
  }
}
</script>
<style lang="scss">
.progress-container {
  position: relative;

  .content {
    opacity: 1;
  }

  &.loading {
    filter: grayscale(100%);
  }

  .progress {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, background 0.3s ease-in-out;
    pointer-events: none;
    background: transparent;

    h3 {
      margin-right: 16px;
    }
  }
}

.progress-container.loading .content {
  filter: blur(4px);
}

.progress-container.loading .progress {
  z-index: 500;
  opacity: 1;
  pointer-events: all;
  background: rgba(255, 255, 255, 0.5);
}
</style>