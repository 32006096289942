export const getUpperLevelDomainFromLocation = () => {
  const hostName= window.location.hostname;
  const hostNameParts = hostName.split('.');
  if (hostNameParts.length <= 2) {
    return hostName;
  }
  return hostNameParts.slice(1).join('.');
}

export const isProductionDomain = (hostName) => {
  const hostNameParts = hostName.split('.');
  return hostNameParts.length === 2;
}