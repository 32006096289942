<template>
  <div class="version">{{releaseTag}}</div>
</template>

<script>
import './Version.scss';

export default {
  computed: {
    releaseTag() {
      return this.$config.RELEASE_TAG || 'Loading...';
    }
  }
};
</script>
