import {
  ASTHMA_REPORT_ACQ_EMPTY,
  ASTHMA_REPORT_EMPTY_ACQ_STATE, ASTHMA_REPORT_EMPTY_AOS_STATE, ASTHMA_REPORT_EMPTY_AQI_STATE,
  ASTHMA_REPORT_EMPTY_SPIROMETRY_STATE
} from "@/AsthmaReportConstants";

export function asthmaReportSerialize(asthmaReport) {
  const {
    id,
    siteId,
    name,
    description = '',
    profileId,
    dateStart,
    dateEnd,
    comments,
    attachedReports,
    attachedReports2019,
    referringClinician,
    state,
    acq,
    spirometry,
    aos,
    aqi
  } = asthmaReport;
  
  return {
    id,
    name,
    description,
    profile_id : profileId,
    start_date: dateStart.toISOString(),
    end_date: dateEnd.toISOString(),
    site_id: siteId,
    type: 'asthma',
    state,
    details: {
      referringClinician: {
        name: referringClinician.name
      },
      comments: {
        insights: comments.insights || '',
        onboarding: comments.onboarding || '',
        spirometry_coaching: comments.spirometryCoaching || '',
        spirometry_notes: comments.spirometryNotes || '',
      },
      attached_reports: {
        spirometry_sessions: (attachedReports.spirometrySessions || []).map(ss => ({
          id: ss.id,
          created_at: ss.created_at,
          comments: {
            main: ss.comments.main
          }
        })),
        prepost: (attachedReports.preposts || []).map(prepost => ({
          id: prepost.id || `${prepost.pre.id}|${prepost.post.id}`,
          pre: {
            id: prepost.pre.id,
            created_at: prepost.pre.created_at
          },
          post: {
            id: prepost.post.id,
            created_at: prepost.post.created_at
          },
          comments: {
            main: prepost.comments.main,
            tolerance: prepost.comments.tolerance,
            symptoms: prepost.comments.symptoms,
            saba_used: prepost.comments.sabaUsed
          }
        }))
      },
      attached_reports_2019: {
        spirometry_sessions: (attachedReports2019.spirometrySessions || []).map(ss => ({
          id: ss.id,
          created_at: ss.created_at,
          comments: {
            main: ss.comments.main
          }
        })),
        prepost: (attachedReports2019.preposts || []).map(prepost => ({
          id: prepost.id || `${prepost.pre.id}|${prepost.post.id}`,
          pre: {
            id: prepost.pre.id,
            created_at: prepost.pre.created_at
          },
          post: {
            id: prepost.post.id,
            created_at: prepost.post.created_at
          },
          comments: {
            main: prepost.comments.main,
            tolerance: prepost.comments.tolerance,
            symptoms: prepost.comments.symptoms,
            saba_used: prepost.comments.sabaUsed
          }
        }))
      },
      acq: acq ? acq : ASTHMA_REPORT_EMPTY_ACQ_STATE,
      spirometry: spirometry ? spirometry : ASTHMA_REPORT_EMPTY_SPIROMETRY_STATE,
      aos: aos ? aos : ASTHMA_REPORT_EMPTY_AOS_STATE,
      aqi: aqi ? aqi : ASTHMA_REPORT_EMPTY_AQI_STATE
    }
  }
}

/**
 * Patch on the backend is different than Post.
 *
 * @param asthmaReport
 * @returns {{end_date: *, name, description: string, details: {attached_reports: {spirometry_sessions: {comments: *, id: *}[], prepost: {pre: *, comments: {symptoms: string|*, saba_used: string|*, main: *, tolerance: number|number|string|*}, post: *}[]}, comments: {spirometry_notes: string, insights: string, spirometry_coaching: string, onboarding: string}}, state, start_date: *}}
 */
export function asthmaReportPatchSerialize(report) {
  const {
    name,
    state,
    description = '',
    dateStart,
    dateEnd,
    comments,
    attachedReports,
    attachedReports2019,
    referringClinician,
    acq,
    spirometry,
    aos,
    aqi
  } = report;

  return {
    name,
    description,
    start_date: dateStart.toISOString(),
    end_date: dateEnd.toISOString(),
    state,
    details: {
      referringClinician: {
        name: referringClinician.name
      },
      comments: {
        insights: comments.insights || '',
        onboarding: comments.onboarding || '',
        spirometry_coaching: comments.spirometryCoaching || '',
        spirometry_notes: comments.spirometryNotes || '',
      },
      attached_reports: {
        spirometry_sessions: (attachedReports.spirometrySessions || []).map(ss => ({
          id: ss.id,
          created_at: ss.created_at,
          comments: {
            main: ss.comments.main
          }
        })),
        prepost: (attachedReports.preposts || []).map(prepost => ({
          id: prepost.id || `${prepost.pre.id}|${prepost.post.id}`,
          pre: {
            id: prepost.pre.id,
            created_at: prepost.pre.created_at
          },
          post: {
            id: prepost.post.id,
            created_at: prepost.post.created_at
          },
          comments: {
            main: prepost.comments.main,
          }
        }))
      },
      attached_reports_2019: {
        spirometry_sessions: (attachedReports2019.spirometrySessions || []).map(ss => ({
          id: ss.id,
          created_at: ss.created_at,
          comments: {
            main: ss.comments.main
          }
        })),
        prepost: (attachedReports2019.preposts || []).map(prepost => ({
          id: prepost.id || `${prepost.pre.id}|${prepost.post.id}`,
          pre: {
            id: prepost.pre.id,
            created_at: prepost.pre.created_at
          },
          post: {
            id: prepost.post.id,
            created_at: prepost.post.created_at
          },
          comments: {
            main: prepost.comments.main,
          }
        }))
      },
      acq: acq ? {...ASTHMA_REPORT_EMPTY_ACQ_STATE, ...acq} : ASTHMA_REPORT_EMPTY_ACQ_STATE,
      spirometry: spirometry ? {...ASTHMA_REPORT_EMPTY_SPIROMETRY_STATE, ...spirometry} : ASTHMA_REPORT_EMPTY_SPIROMETRY_STATE,
      aos: aos ? aos : ASTHMA_REPORT_EMPTY_AOS_STATE,
      aqi: aqi ? aqi : ASTHMA_REPORT_EMPTY_AQI_STATE
    }
  }
}

export function asthmaReportDeserialize(asthmaReportRaw) {
  const acqData = asthmaReportRaw.details.acq ? asthmaReportRaw.details.acq : ASTHMA_REPORT_EMPTY_ACQ_STATE;

  acqData.dates = (acqData.dates || ASTHMA_REPORT_ACQ_EMPTY).map(isoDS => {
    return isoDS ? new Date(isoDS) : undefined
  });

  // Old reports may not have the 4 comments fields: main, tolerance, symptoms, saba_used
  // Since we switched to one comment, we need to combine all of them into one
  const combineComments = (...comments) => comments.filter(c => !!c).join('\n');

  return asthmaReportRaw ? {
    id : asthmaReportRaw.id,
    name : asthmaReportRaw.name,
    description: asthmaReportRaw.description,
    type : 'Asthma',
    state: asthmaReportRaw.state,
    profileId : asthmaReportRaw.profile_id,
    dateStart: new Date(asthmaReportRaw.start_date),
    dateEnd: new Date(asthmaReportRaw.end_date),
    createdAt: new Date(asthmaReportRaw.created_at),
    createdBy: asthmaReportRaw.createdBy ? {
      id : asthmaReportRaw.createdBy.id,
      firstName : asthmaReportRaw.createdBy.first_name,
      lastName : asthmaReportRaw.createdBy.last_name
    } : undefined,
    updatedAt: new Date(asthmaReportRaw.updatedAt),
    updatedBy: asthmaReportRaw.updatedBy ? {
      id : asthmaReportRaw.updatedBy.id,
      firstName : asthmaReportRaw.updatedBy.first_name,
      lastName : asthmaReportRaw.updatedBy.last_name
    } : undefined,
    comments: {
      insights: asthmaReportRaw.details.comments.insights,
      onboarding: asthmaReportRaw.details.comments.onboarding,
      spirometryCoaching: asthmaReportRaw.details.comments.spirometry_coaching,
      spirometryNotes: asthmaReportRaw.details.comments.spirometry_notes
    },
    referringClinician: {
      name: asthmaReportRaw.details.referringClinician ? asthmaReportRaw.details.referringClinician.name : ''
    },
    attachedReports: {
      spirometrySessions: asthmaReportRaw.details.attached_reports?.spirometry_sessions.map(ss => ({
        id: ss.id,
        created_at: ss.created_at,
        comments: {
          main: ss.comments.main
        }
      })),
      preposts: asthmaReportRaw.details.attached_reports?.prepost.map(prepost => ({
        id: prepost.id || `${prepost.pre.id}|${prepost.post.id}`,
        pre: {
          id: prepost.pre.id,
          created_at: prepost.pre.created_at
        },
        post: {
          id: prepost.post.id,
          created_at: prepost.post.created_at
        },
        comments: {
          main: combineComments(
            prepost.comments.main,
            prepost.comments.tolerance,
            prepost.comments.symptoms,
            prepost.comments.saba_used
          )
        }
      }))
    },
    attachedReports2019: {
      spirometrySessions: asthmaReportRaw.details.attached_reports_2019?.spirometry_sessions.map(ss => ({
        id: ss.id,
        created_at: ss.created_at,
        comments: {
          main: ss.comments.main
        }
      })),
      preposts: asthmaReportRaw.details.attached_reports_2019?.prepost.map(prepost => ({
        id: prepost.id || `${prepost.pre.id}|${prepost.post.id}`,
        pre: {
          id: prepost.pre.id,
          created_at: prepost.pre.created_at
        },
        post: {
          id: prepost.post.id,
          created_at: prepost.post.created_at
        },
        comments: {
          main: combineComments(
            prepost.comments.main,
            prepost.comments.tolerance,
            prepost.comments.symptoms,
            prepost.comments.saba_used
          )
        }
      }))
    },
    // When deserializing we default to the empty state for old reports that were saved without ACQ
    acq: asthmaReportRaw.details.acq ? asthmaReportRaw.details.acq : ASTHMA_REPORT_EMPTY_ACQ_STATE,
    spirometry: asthmaReportRaw.details.spirometry ? asthmaReportRaw.details.spirometry : ASTHMA_REPORT_EMPTY_SPIROMETRY_STATE,
    aos: asthmaReportRaw.details.aos ? asthmaReportRaw.details.aos : ASTHMA_REPORT_EMPTY_AOS_STATE,
    aqi: asthmaReportRaw.details.aqi ? asthmaReportRaw.details.aqi : ASTHMA_REPORT_EMPTY_AQI_STATE
  } : undefined;
}
