//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  errors: [],
  successes: []
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  /**
   * Push one error to the snackbar.
   */
  error: function(state, error) {
    state.errors.push({
      error,
      time: new Date().getTime()
    });
  },
  /**
   * Clear all errors in the snackbar.
   */
  clearErrors: function(state) {
    state.errors = [];
  },
  /**
   * Push one success to the snackbar.
   */
  success: function(state, { message, timeoutMs }) {
    state.successes.push({
      message,
      timeoutMs,
      time: new Date().getTime()
    });
  },
  /**
   * Remove a success from the snackbar.
   */
  removeSuccess: function(state, obj) {
    const successes = state.successes.concat();

    const ix = successes.indexOf(obj);
    successes.splice(ix, 1);

    state.successes = successes;
  },
  /**
   * Clear all successes in the snackbar.
   */
  clearSuccesses: function(state, obj) {
    state.successes = [];
  }
};