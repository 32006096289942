// ------------------------------------------------------------------------------
//
// NOTE: these action types are NOT Vuex actions, they are actions allowed
// on the backend.
//
// ------------------------------------------------------------------------------

// ------------------------------------------------------------------------------
// Portal Actions
// ------------------------------------------------------------------------------
export const ACTION_TYPE_PORTAL_SETTINGS_VIEW = 'action_portal_settings_view';
export const ACTION_TYPE_PORTAL_SETTINGS_EDIT = 'action_portal_settings_edit';

// ------------------------------------------------------------------------------
// Profile Actions
// ------------------------------------------------------------------------------
export const ACTION_TYPE_PROFILE_VIEW = 'action_profile_view';
export const ACTION_TYPE_PROFILE_EDIT = 'action_profile_edit';
export const ACTION_TYPE_PROFILE_ADD = 'action_profile_add';
export const ACTION_TYPE_PROFILE_DELETE = 'action_profile_delete';

// ------------------------------------------------------------------------------
// User Actions
// ------------------------------------------------------------------------------
export const ACTION_TYPE_USER_VIEW = 'action_user_view';
export const ACTION_TYPE_USER_ADD = 'action_user_add';
export const ACTION_TYPE_USER_EDIT = 'action_user_edit';
export const ACTION_TYPE_USER_DELETE = 'action_user_delete';
export const ACTION_TYPE_USER_GIVE_PORTAL_ACCESS = 'action_user_give_portal_access';
export const ACTION_TYPE_USER_REVOKE_PORTAL_ACCESS = 'action_user_revoke_portal_access';

// ------------------------------------------------------------------------------
// Site User Actions
// ------------------------------------------------------------------------------
export const ACTION_TYPE_SITE_USER_VIEW = 'action_site_user_view';
export const ACTION_TYPE_SITE_USER_ADD = 'action_site_user_add';
export const ACTION_TYPE_SITE_USER_EDIT = 'action_site_user_edit';
export const ACTION_TYPE_SITE_USER_DELETE = 'action_site_user_delete';
export const ACTION_TYPE_SITE_USER_EDIT_PERMISSIONS = 'action_site_user_edit_permissions';
export const ACTION_TYPE_SITE_USER_RESET_PASSWORD = 'action_site_user_reset_password';

// ------------------------------------------------------------------------------
// Site Actions
// ------------------------------------------------------------------------------
export const ACTION_TYPE_SITE_VIEW_SECURE_SETTINGS = 'action_site_view_settings';
export const ACTION_TYPE_SITE_ADD = 'action_site_add';
export const ACTION_TYPE_SITE_EDIT = 'action_site_edit';
export const ACTION_TYPE_SITE_DELETE = 'action_site_delete';

// ------------------------------------------------------------------------------
// Report Actions
// ------------------------------------------------------------------------------
export const ACTION_REPORT_ADD_EDIT = 'report_add_edit'
export const ACTION_REPORT_VIEW = 'report_view'
export const ACTION_REPORT_VIEW_FINAL = 'report_view_final'
export const ACTION_REPORT_SOFT_DELETE = 'report_soft_delete'

// ------------------------------------------------------------------------------
// SMS Template Actions
// ------------------------------------------------------------------------------
export const ACTION_TYPE_SMS_TEMPLATE_EDIT_DELETE_OWN = 'sms_template_edit_delete_own'
export const ACTION_TYPE_SMS_TEMPLATE_EDIT_DELETE_ANY = 'sms_template_edit_delete_any'

export const ACTION_LABEL_MAP = {
  [ACTION_TYPE_PORTAL_SETTINGS_VIEW]: 'Can view user portal settings',
  [ACTION_TYPE_PORTAL_SETTINGS_EDIT]: 'Can edit user portal settings',
  [ACTION_TYPE_PROFILE_VIEW]: 'Can view patient profiles',
  [ACTION_TYPE_PROFILE_EDIT]: 'Can edit patient profiles',
  [ACTION_TYPE_PROFILE_ADD]: 'Can add patient profiles',
  [ACTION_TYPE_PROFILE_DELETE]: 'Can delete profiles',
  [ACTION_TYPE_USER_VIEW]: 'Can view portal users',
  [ACTION_TYPE_USER_ADD]: 'Can add portal users',
  [ACTION_TYPE_USER_EDIT]: 'Can edit portal users',
  [ACTION_TYPE_USER_DELETE]: 'Can delete portal users',
  [ACTION_TYPE_USER_GIVE_PORTAL_ACCESS]: 'Can give users access to the portal',
  [ACTION_TYPE_USER_REVOKE_PORTAL_ACCESS]: 'Can revoke users access to the portal',
  [ACTION_TYPE_SITE_USER_VIEW]: 'Can view portal user data within a site',
  [ACTION_TYPE_SITE_USER_ADD]: 'Can add portal user data within a site',
  [ACTION_TYPE_SITE_USER_EDIT]: 'Can edit portal user data within a site',
  [ACTION_TYPE_SITE_USER_DELETE]: 'Can remove a portal user from a site',
  [ACTION_TYPE_SITE_USER_RESET_PASSWORD]: 'Can reset a user\'s password',
  [ACTION_TYPE_SITE_VIEW_SECURE_SETTINGS]: 'Can view site secure settings',
  [ACTION_TYPE_SITE_ADD]: 'Can add sites',
  [ACTION_TYPE_SITE_EDIT]: 'Can edit sites',
  [ACTION_TYPE_SITE_DELETE]: 'Can delete sites',
  [ACTION_REPORT_VIEW]: "Can view reports",
  [ACTION_REPORT_VIEW_FINAL]: "Can view finalized reports",
  [ACTION_REPORT_ADD_EDIT]: "Can add/edit reports",
  [ACTION_REPORT_SOFT_DELETE]: "Can soft-delete reports",
  [ACTION_TYPE_SMS_TEMPLATE_EDIT_DELETE_OWN]: "Can edit or delete site user's own sms template",
  [ACTION_TYPE_SMS_TEMPLATE_EDIT_DELETE_ANY]: "Can edit or delete any site user's sms template",
};

export const ACTIONS_VISIBLE_IN_PERMISSIONS = [
  ACTION_TYPE_PORTAL_SETTINGS_EDIT,
  ACTION_TYPE_USER_ADD,
  ACTION_TYPE_USER_EDIT,
  ACTION_TYPE_USER_GIVE_PORTAL_ACCESS,
  ACTION_TYPE_USER_REVOKE_PORTAL_ACCESS,
  ACTION_TYPE_SITE_USER_RESET_PASSWORD
];

// ------------------------------------------------------------------------------
// Modules
// ------------------------------------------------------------------------------
export const MODULES = {
  ARIA_MODULE_CF: 'aria_module_cf',
  ARIA_MODULE_ASTHMA: 'aria_module_asthma',
  ARIA_MODULE_DEFAULT: 'aria_module_default'
};
