import moment from 'moment';

export const state = () => ({
    accountDetail: null,
    rewardActivities: [],
    rewardTypes: {},
    rewardStatuses: [],
    isLoading: false,
    error: null
});

export const mutations = {
    SET_ACCOUNT_DETAIL(state, detail) {
        state.accountDetail = detail;
    },
    SET_REWARD_ACTIVITIES(state, activities) {
        state.rewardActivities = activities;
    },
    SET_REWARD_TYPES(state, types) {
        state.rewardTypes = types;
    },
    SET_REWARD_STATUSES(state, statuses) {
        state.rewardStatuses = statuses;
    },
    SET_LOADING(state, loading) {
        state.isLoading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    UPDATE_REWARD_ACTIVITY(state, updatedActivity) {
        const index = state.rewardActivities.findIndex(activity => activity.id === updatedActivity.id);
        if (index !== -1) {
            state.rewardActivities.splice(index, 1, updatedActivity);
        }
    }
};

export const actions = {
    async fetchAccountDetail({ commit }, profileId) {
        commit('SET_LOADING', true);
        try {
            const response = await this.$apiv2.GET(`/gateway/services/patient-log/api/v1/reward/account-detail?profile_id=${profileId}`);
            commit('SET_ACCOUNT_DETAIL', response.data);
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async fetchRewardActivities({ commit }, profileId) {
        commit('SET_LOADING', true);
        try {
            const response = await this.$apiv2.GET(`/gateway/services/patient-log/api/v1/reward/reward-activity?profile_id=${profileId}`);
            commit('SET_REWARD_ACTIVITIES', response.data);
        } catch (error) {
            commit('SET_ERROR', error);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async fetchRewardTypes({ commit }) {
        try {
            const response = await this.$apiv2.GET('/gateway/services/patient-log/api/v1/reward/list-of-types');
            commit('SET_REWARD_TYPES', response.data);
        } catch (error) {
            commit('SET_ERROR', error);
        }
    },

    async fetchRewardStatuses({ commit }) {
        try {
            const response = await this.$apiv2.GET('/gateway/services/patient-log/api/v1/reward/list-of-status');
            commit('SET_REWARD_STATUSES', response.data);
        } catch (error) {
            commit('SET_ERROR', error);
        }
    },

    async updateRewardActivity({ commit }, { activityId, payload }) {
        commit('SET_LOADING', true);
        try {
            const response = await this.$apiv2.PATCH(`/gateway/services/patient-log/api/v1/reward/reward-activity/${activityId}`, payload);
            commit('UPDATE_REWARD_ACTIVITY', response.data);
            return response.data;
        } catch (error) {
            commit('SET_ERROR', error);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    }
};

export const getters = {
    isEligible: state => !state.accountDetail?.isEligibleToEarnReward ?? false,
    currentEarnings: state => state.accountDetail?.earningAmountToDate ?? 0,
    maxEarnings: state => state.accountDetail?.maxEarningAmountAnnual ?? 0,
    startDate: state => state.accountDetail?.startDateOfCurrentMonth,
    endDate: state => state.accountDetail?.endDateOfCurrentMonth,
    earningsProgress: state => state.accountDetail?.earningPercentage ?? 0
}; 
