<template>
  <progress-container :loading='loading'>
    <slot/>
  </progress-container>
</template>

<script>
  import LoaderMixin from "./LoaderMixin";
  import ProgressContainer from '@/components/progress/ProgressContainer'

  export default {
    components: { ProgressContainer },
    mixins: [LoaderMixin],
    methods: {
      load() {
        if (this.$store.state.portal.settings) {
          return Promise.resolve();
        }

        return this.$apiv2.getPortalSettings()
            .then(result => this.commit('portal', 'setPortalSettings', result.data.portal_settings));
      }
    }
  };
</script>
