<template>
    <SidebarContent :id="id" :payload="payload">
        <template v-slot:sidebar-header>
            <div>
                <div class="font-medium text-2xl">Calendar</div>
                <div class="flex justify-content-between align-items-center mt-5">
                    <Button icon="pi pi-chevron-left" class="p-button-link p-button-sm" @click="goToPreviousDay" :disabled="loadingAppointments" />
                    <h4 class="text-xl">{{ formattedSelectedDate }}</h4>
                    <Button icon="pi pi-chevron-right" class="p-button-link p-button-sm" @click="goToNextDay" :disabled="loadingAppointments" />
                </div>
            </div>
        </template>

        <template v-slot:sidebar-content>
            <TabView :activeIndex.sync="activeIndex">
                <TabPanel header="My Appointments">
                    <div v-if="loadingAppointments" class="text-center">Loading...</div>
                    <div v-else-if="errorAppointments" class="text-center text-red-500">Error loading appointments.
                    </div>
                    <div v-else-if="transformedSortedAppointments.length === 0" class="text-center">No appointments
                        found.
                    </div>
                    <div v-else>
                        <div v-for="appointment in transformedSortedAppointments" :key="appointment.id"
                            class="flex-column mb-4 px-3 py-2 shadow-sm border-2 border-round-2xl"
                            :class="[appointment.status === 'FULFILLED' ? 'opacity-50 bg-white' : '', appointment.status === 'NOW' ? 'bg-green-100  border-bluegray-100' : 'bg-white border-bluegray-50']">
                            <div class="flex justify-content-between">
                                <Badge :value="appointment.type" :severity="typeSeverity(appointment.type)" />
                                <Badge :value="statusToText(appointment.status)"
                                    :severity="statusSeverity(appointment.status)" />
                            </div>
                            <div class="font-bold font-semibold	">
                                {{ appointment.time }}
                            </div>
                            <a :href="appointment.patientUrl" class="font-semibold">{{ appointment.patientName }}</a>
                            <div>{{ renderPurposeText(appointment.purpose) || '-' }}</div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Care Team Links">
                    <!-- Create User Role Dropdown Filter -->
                    <!-- <Dropdown v-model="selectedRole" :options="allSiteUserRoles" optionLabel="portalLabel" placeholder="Select a role" /> -->
                    <MultiSelect class="w-full mb-4" v-model="selectedRoles" :options="allSiteUserRoles"
                        optionLabel="portalLabel" placeholder="Select a role" display="chip" />
                    <div v-if="loadingCareTeam" class="text-center">Loading...</div>
                    <div v-else-if="errorCareTeam" class="text-center text-red-500">Error loading care team links.</div>
                    <div v-else-if="filteredBookingLinks.length === 0" class="text-center">No care team links found.</div>
                    <div v-else>
                        <div v-for="member in filteredBookingLinks" :key="member.userId"
                            class="flex-column mb-4 px-3 py-2 shadow-sm border-2 border-round-2xl 'bg-white border-bluegray-50">
                            <Badge :value="getMemberRole(member).name"
                                :severity="memberSeverity(getMemberRole(member).code)" />
                            <div v-if="member.userIdPii && (member.userIdPii.firstName || member.userIdPii.lastName)">
                                <span class="font-semibold">{{ member.userIdPii.firstName }} {{
        member.userIdPii.lastName }}</span>
                            </div>
                            <div v-else>
                                <span class="font-semibold">{{ member.email }}</span>
                            </div>
                            <div v-for="link in member.bookingLinks" :key="link.id"
                                class="flex justify-content-between align-items-center mb-2">
                                <div>{{ link.title }}</div>
                                <div>
                                    <Button icon="pi pi-copy" class="p-button-link p-button-sm"
                                        @click="copyBookingLink(link.bookingLink)" />
                                    <Button icon="pi pi-external-link" class="p-button-sm"
                                        @click="openLink(link.bookingLink)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </template>
    </SidebarContent>
</template>

<script>
import SidebarContent from '../SidebarContent.vue';
import { ref, computed, onMounted, watch } from '@vue/composition-api';
import { useAppointments } from '@/composables/appointments';
import { capitalizeFirst, getNameFromPii, formatDateRange } from '@/helpers';
import { slugFromSite } from "@/utils/modelUtil";
import { copyToClipboard } from '@/utils/copyToClipboard';

import moment from 'moment';

export default {
    components: {
        SidebarContent
    },
    props: {
        id: Number,
        payload: Object,
    },
    setup (props, { root }) {
        const user = ref(root.$auth.user);
        const { appointments, loading: loadingAppointments, error: errorAppointments, fetchAppointments, fetchCareTeamBookingLinks, userMappedBookingLinks, loadingCareTeam } = useAppointments();
        const errorCareTeam = ref(false);
        const careTeamLinks = ref([]);
        const activeIndex = ref(props.payload?.selectedTab || 0);

        const selectedDate = ref(moment().toDate());

        const formattedSelectedDate = computed(() => {
            return moment().isSame(moment(selectedDate.value), 'day') ? 'Today' : moment(selectedDate.value).format('LL');
        });
        const selectedRoles = ref(null);

        const goToPreviousDay = () => {
            selectedDate.value = moment(selectedDate.value).subtract(1, 'days').toDate();
        }
        const goToNextDay = () => {
            selectedDate.value = moment(selectedDate.value).add(1, 'days').toDate();
        }

        const transformedSortedAppointments = computed(() => {
            return appointments.value?.map(appointment => {
                const source = appointment.calendarEventId ? 'GOOGLE_CALENDAR' : 'MANUAL';
                const site = getSiteByAppointment(appointment);
                const canAccessSite = getAccessibleSiteProfilesByAppointment(appointment)?.length;
                const slug = site ? slugFromSite(site) : '';
                const profileId = getSiteProfileIdByAppointment(appointment);

                return {
                    ...appointment,
                    status: moment(appointment.startTime).isSameOrAfter(moment()) && moment(appointment.startTime).isSameOrBefore(moment().add(30, 'minutes')) ? 'NOW' : appointment.status,
                    source,
                    time: moment(appointment.startTime).format('LT'),
                    type: source === 'GOOGLE_CALENDAR' ? 'Appointment' : 'Follow Up',
                    purpose: appointment.purpose,
                    patientName: appointment.principalPii ? getNameFromPii(appointment.principalPii) : '',
                    patientUrl: canAccessSite && slug && profileId ? `/site/${slug}/patient/${profileId}?tab=communication` : null,
                };
            }).sort((a, b) => moment(a.startTime).diff(moment(b.startTime)));
        });

        const sites = computed(() => root.$store.state.portal.sites);
        const allSiteUserRoles = computed(() => root.$store.state.portal.allSiteUserRoles);
        const communicationPurposes = computed(() => root.$store.state.portal.communicationPurposes);

        // set the my booking links at the top. match by user id
        const sortedBookingLinks = computed(() => {
            return userMappedBookingLinks.value.sort((a, b) => a.userId === root.$store.getters['portal/userId'] ? -1 : 1);
        });

        const getMemberRole = (member) => {
            return member.userIdPii.roles?.[0] || 'Unknown';
        }

        const filteredBookingLinks = computed(() => {
            // need to check "code" key if matches link.role
            if (!selectedRoles.value || selectedRoles.value.length === 0) return sortedBookingLinks.value;
            // TODO: null check to be removed
            return sortedBookingLinks.value.filter(member => selectedRoles.value.some(role => member.userIdPii.roles?.some(r => r.code === role.code)));
        });

        const getAccessibleSiteProfilesByAppointment = (appointment) => {
            // For the appointment's principal, returns the site profile(s) to which the user has access.
            const siteProfiles = appointment.principalPii?.siteProfiles;
            return siteProfiles?.filter(sp => sites.value.map(site => site.id).includes(sp.site.id));
        };


        const preferredSiteProfile = (appointment) => {
            // Returns one site profile for the appointment's principal, prioritizing a site the user has access to.
            const accessibleSiteProfiles = getAccessibleSiteProfilesByAppointment(appointment);
            if (accessibleSiteProfiles?.length) {
                return accessibleSiteProfiles[0];
            }
            return appointment.principalPii?.siteProfiles?.[0] || null;
        };
        const getSiteByAppointment = (appointment) => {
            return preferredSiteProfile(appointment)?.site || null;
        };
        const getSiteProfileIdByAppointment = (appointment) => {
            return preferredSiteProfile(appointment)?.id || null;
        };

        const copyBookingLink = (link) => {
            copyToClipboard(link)
                .then(() => {
                    root.$toast.add({ severity: 'success', summary: 'Success', detail: 'Onboarding link copied to clipboard', life: 3000 });
                })
                .catch(err => {
                    root.$toast.add({ severity: 'error', summary: 'Error', detail: `Could not copy onboarding link: ${err.message}`, life: 5000 });
                });
        }

        const openLink = (link) => {
            window.open(link, '_blank');
        }

        const statusToText = (status) => {
            return capitalizeFirst(status.replace(/_/g, ' '))
        };

        const renderPurposeText = (purpose) => {
            return communicationPurposes.value.find(p => p.id === purpose)?.name
        };

        const statusSeverity = (status) => {
            switch (status) {
                case 'PENDING':
                case 'PROPOSED':
                    return 'warning'
                case 'BOOKED':
                    return 'info'
                case 'NOW':
                case 'FULFILLED':
                    return 'success'
                case 'CANCELLED':
                    return 'danger'
                default:
                    return 'info'
            }
        };

        const typeSeverity = (type) => {
            return type === 'Appointment' ? 'success' : 'info'
        }


        const memberSeverity = (roleCode) => {
            // success
            // info
            // warning
            // danger
            switch (roleCode) {
                case 'health_coach':
                case 'patient_enrollment_coordinator':
                    return 'info'
                case 'nurse_practitioner':
                case 'registered_nurse':
                case 'pulmonologist':
                    return 'success'
                case 'caregiver_lead':
                case 'respiratory_therapist':
                    return 'warning'
                default:
                    return 'info'
            }
        }


        async function fetchCareTeamLinks () {
            try {
                const res = await fetchCareTeamBookingLinks();
            } catch (e) {
                errorCareTeam.value = true;
            }
        }

        const loadAppointments = async () => {
            const startDate = moment(selectedDate.value).startOf('day').toDate();
            const endDate = moment(selectedDate.value).endOf('day').toDate();
            const params = {
                filters:{
                    assignedPractitioner: { value: user.value.id, matchMode: 'equals' }
                },
                rows: 100,
                startDate,
                endDate,
            };
            await fetchAppointments(params);
        }

        watch(activeIndex, async (newIndex) => {
            if (newIndex === 0) {
                await loadAppointments();
            } else if (newIndex === 1) {
                await fetchCareTeamLinks();
            }
        });
        watch(selectedDate, () => {
            loadAppointments();
        });


        onMounted(() => {
            loadAppointments();
            fetchCareTeamLinks();
        });

        return {
            formattedSelectedDate,
            transformedSortedAppointments,
            loadingAppointments,
            errorAppointments,
            loadingCareTeam,
            errorCareTeam,
            sortedBookingLinks,
            selectedDate,
            activeIndex,
            statusSeverity,
            statusToText,
            renderPurposeText,
            typeSeverity,
            memberSeverity,
            copyBookingLink,
            openLink,
            goToPreviousDay,
            goToNextDay,
            selectedRoles,
            filteredBookingLinks,
            allSiteUserRoles,
            getMemberRole,
        };
    },
};
</script>
<style lang="scss" scoped>
.sidebar-main {
    padding: 0px;

    ::v-deep .sidebar-header {
        margin: 16px 24px;
    }
}

::v-deep .p-tabview {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .p-tabview-panels {
        padding: 16px;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
    }

    .p-tabview-nav {
        padding: 0px;

        li {
            flex: 1;

            .p-tabview-nav-link {
                justify-content: center;
            }
        }
    }
}

</style>
