<template>
  <div>
    <Toast position="bottom-left" />
    <router-view />
  </div>
</template>

<script>
export default {
};
</script>
