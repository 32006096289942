// See: https://vuetifyjs.com/en/components/forms/#validation-with-submit-26-clear
export const userFormFirstNameRules = () => {
  return [
    v => (v || '').length > 1 || 'First name needs at least 2 characters',
    v => (v || '').length < 25 || 'First cannot be more than 25 characters',
  ];
};

export const userFormLastNameRules = () => {
  return [
    v => (v || '').length > 1 || 'Last name needs at least 2 characters',
    v => (v || '').length < 25 || 'Last cannot be more than 25 characters',
  ];
};

export const userFormEmailRules = () => {
  return [
    v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v || '') || 'Not a valid email address.'
  ];
};

export const userFormPasswordRules = () => {
  return [
    v => v && v.length > 2 // For old passwords they may have entered something ridiculous so we don't check this too closely.
  ]
}

export const passwordRules = () => {
  return [
    v => (v || '').length >= 8 || 'Password needs at least 8 characters',
    v => /[A-Z]/g.test(v || '') || 'Password needs at least 1 uppercase character',
    v => /[a-z]/g.test(v || '') || 'Password needs at least 1 lowercase character',
    v => /[^a-zA-Z0-9]/g.test(v || '') || 'Password needs at least 1 non-alphanumberic character (e.g. -_?!)',
  ];
};

export const phoneCountryCodeRules = () => {
  return [
    v => ((v || '').length === 0 || (v || '').length >= 2) || 'Country code must be a + followed by at least one digit (e.g. +44).',
    v => ((v || '').length === 0 || (v || '').startsWith('+')) || 'Country code must be prefixed by a + (e.g. +44).',
    v => ((v || '').length === 0 || /^[+][0-9][0-9]?[0-9]?$/g.test(v || '')) || 'Country code must be a + or digits only (e.g. +44).',
  ];
};

export const phoneNumberRules = () => {
  return [
    v => ((v || '').length === 0 || (v || '').length >= 5) || 'Phone number must be at least five digits',
    v => ((v || '').length === 0 || /^[0-9]+$/g.test(v || '')) || 'Phone number must be digits only.',
  ];
};

export const userFormRegionRules = () => {
  return [
    v => !!v || 'Region must be selected'
  ]
}