const middleware = {}

middleware['onLoad'] = require('../middleware/onLoad.js')
middleware['onLoad'] = middleware['onLoad'].default || middleware['onLoad']

middleware['sso'] = require('../middleware/sso.js')
middleware['sso'] = middleware['sso'].default || middleware['sso']

middleware['userNoPortalAccessCheck'] = require('../middleware/userNoPortalAccessCheck.js')
middleware['userNoPortalAccessCheck'] = middleware['userNoPortalAccessCheck'].default || middleware['userNoPortalAccessCheck']

export default middleware
