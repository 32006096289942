import { datadogLogs } from '@datadog/browser-logs'

// See: https://docs.datadoghq.com/logs/log_collection/javascript/
export default function({ $config }, inject) {
  if (!$config.DD_ENABLED) {
    console.log("Datadog log collection is disabled");
    return;
  }

  const ddConfig = {
    site: $config.DD_SITE,
    service: $config.DD_SERVICE,
    env: $config.DD_ENV,
    version: $config.DD_VERSION,
    clientToken: $config.DD_CLIENT_TOKEN,
  };

  console.log("Setting up datadog log collection:", ddConfig);
  datadogLogs.init(ddConfig);
}
