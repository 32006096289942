<template>
  <span class="patient-search-input p-float-label my-4">
    <div :class="formFieldDivSelfClass">
      <slot v-if="$slots.customInput" name="customInput"></slot>
      <InputText v-else :id="`patient-search-${fieldKey}`" class="w-full" :class="error ? 'p.invalid' : ''" type="text"
        autocomplete="off" :value="inputValue" @input="text_inputHandler($event)"
        @keyup.enter="$emit('enterPressed')" />
      <label :class="formFieldLabelSelfClass" :for="`patient-search-${fieldKey}`">{{ displayName }}</label>
      <span v-if="isFlexibleMatchCapable" class="p-inputgroup-addon">
        <Checkbox :id="`${fieldKey}-partial`" :binary="true" :model-value="checkboxValue"
          @input="checkbox_inputHandler($event ? 'contains' : 'equals')" />
      </span>
      <small v-if="error" id="help-text" class="ml-2 text-red-400">{{ error }}</small>
    </div>
  </span>
</template>

<script>

export default {
  props: {
    fieldKey: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
    isFlexibleMatchCapable: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: String,
    },
    checkboxValue: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
    }
  },
  computed: {
    formFieldDivSelfClass () {
      return this.isFlexibleMatchCapable ? 'p-inputgroup' : 'p-float-label my-3';
    },
    formFieldLabelSelfClass () {
      return this.isFlexibleMatchCapable ? 'label-with-partial' : '';
    },
  },
  methods: {
    checkbox_inputHandler (value) {
      this.$emit('checkboxSelected', this.fieldKey, 'matchMode', value);
    },
    text_inputHandler (value) {
      this.$emit('inputChange', this.fieldKey, 'value', value);
    },
  },
}
</script>

<style lang="scss" scoped>
.patient-search-input {
  .p-inputtext {
    padding: 1rem 0.75rem 0.25rem;
  }

  .p-inputgroup-addon {
    padding: 0 0.75rem;
  }

  .partial-label {
    position: relative;
    top: 0;
    margin-top: 0px;
    margin-left: 4px;
  }

  .label-with-partial {
    color: #6c757d;
    padding-left: 6px;
    margin-left: 4px;
    transition-duration: 0.2s;
  }

  ::v-deep .p-checkbox-icon {
    box-sizing: content-box;

    &.pi-check:before {
      top: 8px;
      left: 3px;
    }
  }

  ::v-deep .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 8px;
    left: 3px;
  }

  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
}
</style>