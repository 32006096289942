<template>
  <v-snackbar :value="successes.length > 0"
              :timeout="-1"
              color="#00CC00">
    <div class="snackbar-success" v-for="success in successes" :key='success.time'>
      {{success.message}}
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn color="white"
             text
             v-bind="attrs"
             @click="closeSnackbar()">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import PortalComponentMixin from "../../mixins/PortalComponentMixin";

  export default {
    mixins: [PortalComponentMixin],
    data() {
      return {
        successes: []
      }
    },
    mounted() {
      setInterval(() => {
        this.successes.forEach(success => {
          if (success.timeoutMs !== undefined) {
            const now = new Date().getTime();

            if (now - success.timeoutMs > success.time) {
              this.commit('snackbar', 'removeSuccess', success);
            }
          }
        })
      }, 500);
    },
    methods: {
      closeSnackbar() {
        this.commit('snackbar' , 'clearSuccesses');
      }
    },
    watch: {
      '$store.state.snackbar.successes': function(successes) {
        this.successes = successes;
      }
    }
  };
</script>