<template>
  <div class="position-below-fixed" :style="selfStyle">
    <slot />
  </div>
</template>
<script>
  import './PositionBelowFixed.scss';

  export default {
    props: {
      positionWithMarginTop: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        marginTop: 0,
      }
    },
    computed: {
      selfStyle() {
        return {
          marginTop: this.marginTop + 'px'
        }
      }
    },
    methods: {
      update() {
        let marginTop = 0;

        // Loop over all elements that should be above us, and if they are position fixed,
        // move ourselves below that.
        this.fixedElementIds.forEach(elementId => {
          const el = document.getElementById(elementId);
          const attributes = [...el.attributes];
          const excludeFromLayoutAttr = attributes.find(a => a.name === "data-exclude-from-layout");
          const excludeFromLayout = excludeFromLayoutAttr && excludeFromLayoutAttr.nodeValue === 'true';

          if (el && !excludeFromLayout) {
            const rect = el.getBoundingClientRect();
            const style = window.getComputedStyle(el);
            if (style.position === 'fixed') {
              marginTop = marginTop + rect.height;
            }
          } else {
            console.warn(`Could not find element with id '${elementId}'`);
          }
        });

        this.marginTop = marginTop;
      },
    },
    watch: {
      '$store.state.layout.window.fixedElementIds': function (fixedElementIds) {
        this.fixedElementIds = fixedElementIds;
        this.update();
      },
      '$route': function() {
        this.marginTop = 0;
      }
    }
  }
</script>