<template>
  <window-scroll-detector>
    <v-app>
      <header-app-bar />
      <v-main>
        <router-view />
      </v-main>
      <error-snackbar />
      <success-snackbar />
      <version />
    </v-app>
  </window-scroll-detector>
</template>

<script>
import HeaderAppBar from "@/components/layout/header/HeaderAppBar";
import PortalComponentMixin from "@/mixins/PortalComponentMixin";
import ErrorSnackbar from "@/components/layout/ErrorSnackbar";
import SuccessSnackbar from "@/components/layout/SuccessSnackbar";
import Version from "@/components/Version";

import '@/styles/base.scss';
import WindowScrollDetector from "@/components/layout/positioning/WindowScrollDetector";

export default {
  mixins: [PortalComponentMixin, ErrorSnackbar, SuccessSnackbar],
  components: {
    WindowScrollDetector,
    HeaderAppBar,
    ErrorSnackbar,
    SuccessSnackbar,
    Version
  },
  beforeMount() {
    document.addEventListener('mousedown', this.documentMouseDownHandler)
  },
  methods: {
    documentMouseDownHandler(event) {
      if (event.target.classList[0] === 'v-overlay__scrim') {
        this.commit('portal', 'closeAllDialogs');
      }
    }
  }
};
</script>
