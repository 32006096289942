//--------------------------------------------------------------------------------------------------------
// Default State
//--------------------------------------------------------------------------------------------------------
import {TIMELINE_SOURCE_TAGS} from "@/components/timeline/TimelineConstants";
import cloneDeep from 'clone-deep';
import {
  ASTHMA_REPORT_EMPTY_ACQ_STATE,
  ASTHMA_REPORT_EMPTY_AOS_STATE, ASTHMA_REPORT_EMPTY_AQI_STATE,
  ASTHMA_REPORT_EMPTY_SPIROMETRY_STATE
} from "@/AsthmaReportConstants";

export const ASTHMA_REPORT_EMPTY_STATE = {
  name: undefined,
  state: 'draft',
  referringClinician: {
    name: ''
  },
  comments: {
    insights: '',
    onboarding: '',
    spirometryCoaching: '',
    spirometryNotes: '',
  },
  attachedReports: {
    // { id, created_at, comments: { main } }
    spirometrySessions: [],
    // { id, pre: { id, created_at }, post: { id, created_at }, comments: { main, tolerance, symptoms, sabaUsed } }
    preposts: []
  },
  attachedReports2019: {
    // { id, created_at, comments: { main } }
    spirometrySessions: [],
    // { id, pre: { id, created_at }, post: { id, created_at }, comments: { main, tolerance, symptoms, sabaUsed } }
    preposts: []
  },
  acq: ASTHMA_REPORT_EMPTY_ACQ_STATE,
  spirometry: ASTHMA_REPORT_EMPTY_SPIROMETRY_STATE,
  aos: ASTHMA_REPORT_EMPTY_AOS_STATE,
  aqi: ASTHMA_REPORT_EMPTY_AQI_STATE
};

export const initialState = () => {
  const timelineSourcesByTag = {};

  /**
   * Even though we have a TimelineSourceBase extended model for each timeline source,
   * we store properties we want to watch in the view here as well just for reactivity.
   */
  TIMELINE_SOURCE_TAGS.forEach(sourceTag => {
    timelineSourcesByTag[sourceTag] = {
      loading: false,
      forceRefreshKey: 0, // Increment this to force a refresh of that view
      data: [],
      showGrid: false,
    }
  });

  let filters = {
    rangeName: undefined,
    startDate: new Date(1970, 0, 0),
    endDate: new Date()
  };

  return {
    profile: undefined,
    siteProfile: undefined,
    filters,
    cursorDate: undefined,
    timelineSourcesByTag,
    dateRangeOverlayOpen: false,
    // If 0, we don't fix the days
    dateRangeFixDays: 0,
    // If a previous report has been loaded, this will be populated with that
    fetchedReport: undefined,
    storedFilters: undefined,
    report: cloneDeep(ASTHMA_REPORT_EMPTY_STATE),
    hasChanges: false,
  }
}

export default initialState();