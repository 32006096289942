import { Client } from '@stomp/stompjs';

const callbacks = () => ({
    onConnect: [],
    onDisconnect: []
});

export default class ChatAdapter {
    constructor(app, brokerUrl, options) {
        this.app = app;
        this.brokerUrl = brokerUrl;
        this.callbacks = callbacks();
        this.options = {
            DEBUG: false,
            MESSAGES_BATCH_SIZE: 100,
            MAX_CHANNELS: 1000,
            RECONNECT_DELAY_MS: 5000,
            HEARTBEAT_INCOMING_MS: 4000,
            HEARTBEAT_OUTGOING_MS: 4000,
            ...options
        }
    }

    activate() {
        this.client.activate();
    }

    async deactivate() {
        this.callbacks = callbacks();
        return this.client.deactivate();
    }

    get client() {
        // Lazy initialization
        if (!this._client) {
            const token = this.app.$auth.strategy.token.get();
            const connectHeaders = {
                Authorization: token,
                DeviceToken: 'Care Portal',
            };
            console.log(`Connecting to ${this.brokerUrl}`)
            this._client = new Client({
                brokerURL: this.brokerUrl,
                connectHeaders: connectHeaders,
                debug: (str) => {
                    if (this.options.DEBUG) console.log("ChatAdapter:", str);
                },
                reconnectDelay: this.options.RECONNECT_DELAY_MS,
                heartbeatIncoming: this.options.HEARTBEAT_INCOMING_MS,
                heartbeatOutgoing: this.options.HEARTBEAT_OUTGOING_MS,

                onWebSocketClose: () => {
                    console.log('ChatAdapter: WebSocket connection closed');
                    this.callbacks.onDisconnect.forEach(c => c())
                },

                onConnect: (frame) => {
                    console.log('ChatAdapter: Connected', frame)
                    this.callbacks.onConnect.forEach(c => c())
                },
                onDisconnect: (frame) => {
                    console.log('ChatAdapter: Disconnected'. frame)
                    this.callbacks.onDisconnect.forEach(c => c())
                },
                onStompError: function (frame) {
                    console.error('Broker reported error: ' + frame.headers['message']);
                    console.error('Additional details: ' + frame.body);
                }
            });
        }
        return this._client;
    }

    // Callbacks

    onConnect(callback) {
        this.callbacks.onConnect.push(callback);
    }

    onDisconnect(callback) {
        this.callbacks.onDisconnect.push(callback);
    }

    // Requests

    getChannels() {
        const payload = {
            paging: { page: 0, size: this.options.MAX_CHANNELS, sort: ["lastModifiedDate,desc"] }
        };
        return this.#sendRequest(`/app/getChannels`, payload)
            .then(data => data.channels);
    }

    getMessages(channelId, createdBefore = undefined) {
        const payload = {
            channelId,
            createdBefore,
            paging: { page: 0, size: this.options.MESSAGES_BATCH_SIZE, sort: ["createdDate,desc"] },
        };
        return this.#sendRequest(`/app/getMessages`, payload)
            .then(({ messages, totalCount }) => ({
                messages,
                hasEarlierMessages: totalCount > this.options.MESSAGES_BATCH_SIZE
            }))
    }

    // Subscriptions

    onChannelUpdated(callback) {
        return this.client.subscribe('/topic/channels', (frame) => {
            const { data: { channel } } = JSON.parse(frame.body);
            callback(channel);
        });
    }

    onChannelMessage(principal, onMessage, onMessageStatus) {
        return this.client.subscribe(`/topic/${principal}`, (frame) => {
            const { type, data } = JSON.parse(frame.body);
            switch (type) {
                case 'MESSAGE': return onMessage(data);
                case 'UPDATE_MESSAGE_STATUS': return onMessageStatus(data);
                default: console.log(`Unknown message type: ${type}: ${data}`);
            }
        });
    }

    // Internal

    #sendRequest(destination, payload = {}) {
        return new Promise((resolve, reject) => {
            const { unsubscribe } = this.client.subscribe(destination, (frame) => {
                const { error, data } = JSON.parse(frame.body);
                // Unsubscribe once the response is received (not needed for rabbitmq)
                // unsubscribe();
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            }, { "payload": JSON.stringify(payload) });
        });
    }
}