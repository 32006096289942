<template>
  <v-toolbar v-if="show && controlsShown === 'site-settings' && !forcePasswordResetOnLogin">
    <breadcrumbs v-if="showBreadcrumbs" />
    <div v-if="showControls" class="d-flex flex-row justify-end flex-fill">
      <v-btn
        text
        v-if="userCanAddNewUser()"
        color="primary"
        @click="addUserClickHandler"
        class="ma-2 white--text"
      >
        <v-icon left dark>mdi-account-plus</v-icon>
        Add New User
      </v-btn>
    </div>
  </v-toolbar>
</template>

<script>
import Breadcrumbs from "./Breadcrumbs";
import PortalComponentMixin from "../../mixins/PortalComponentMixin";

export default {
  mixins: [PortalComponentMixin],
  components: {
    Breadcrumbs,
  },
  computed: {
    show() {
      return (
        this.getStorePropertySafe('portal',"me.account_confirmed") &&
        this.getStorePropertySafe('portal',"ui.subheader.show")
      );
    },
    controlsShown() {
      return this.getStorePropertySafe('portal',"ui.subheader.controlsShown");
    },
    showBreadcrumbs() {
      return this.getStorePropertySafe('portal',"ui.subheader.showBreadcrumbs");
    },
    showControls() {
      return this.getStorePropertySafe('portal',"ui.subheader.showControls");
    }
  },
  methods: {
    addUserClickHandler() {
      this.commit('portal', 'openDialog', 'addUser');
    },
  },
};
</script>
