import { isAfterToday } from "../utils/dateUtil";

export const state = () => ({
    loading: false,
    error: '',
    todos: [],
  });
  
  export const mutations = {
    SET_LOADING (state, loading) {
      state.loading = loading;
    },
    SET_ERROR (state, error) {
      state.error = error;
    },
    SET_TODOS (state, todos) {
      state.todos = todos;
    },
  };
  
  export const actions = {
    async getTodos ({ commit }, profileId) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', '');
      try {
        const todos = await this.$apiv2.getTodosByProfileId(profileId);
        commit('SET_TODOS', todos);
      }
      catch (error) {
        commit('SET_ERROR', error);
      }
      finally {
        commit('SET_LOADING', false);
      }
    },
  };
  
  export const getters = {
    todaysTodos: state => {
        return state.todos.filter(todo => {
            const mostRecentDate = new Date(todo.lastCompletedDate || todo.lastModifiedDate);
            mostRecentDate.setHours(mostRecentDate.getHours() + todo.periodInHours);
            return !isAfterToday(mostRecentDate);
        });
    },
    upcomingTodos: state => {
        return state.todos.filter(todo => {
            const mostRecentDate = new Date(todo.lastCompletedDate || todo.lastModifiedDate);
            mostRecentDate.setHours(mostRecentDate.getHours() + todo.periodInHours);
            return isAfterToday(mostRecentDate);
        });
    },
  };
  